import React from "react";
import CheckoutAddressModule from "./CheckoutAddressModule";
import DeliveryAddressInitialState from "../DeliveryAddressInitialState";
import CommonLoader from "../../AppLoaders/CommonLoader";

const CheckoutDeliveryAddressPresentState = ({
  addresses,
  isLoading,
  onSelectAddress,
  onAddAddress,
}) => {
  return (
    <>
      {!isLoading ? (
        addresses?.length > 0 ? (
          <div className="flex flex-col">
            {addresses.map((item) => {
              return (
                <CheckoutAddressModule
                  key={item._id}
                  address={item}
                  onSelect={(value) => {
                    onSelectAddress(value);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <></>
        )
      ) : (
        <>
          <div className="flex flex-col justify-center items-center">
            <CommonLoader />
            <p className="font-medium text-sm md:text-base">
              Updating Delivery Addresses
            </p>
          </div>
        </>
      )}
      <DeliveryAddressInitialState
        isLoading={isLoading}
        addressLength={addresses?.length}
        onAddAddress={onAddAddress}
      />
    </>
  );
};

export default CheckoutDeliveryAddressPresentState;
