import React, { useEffect, useState } from "react";
import url from "../../../Development.json";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { errorResponse } from "../../Helpers/response.js";
import Http from "../../security/Http";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import useLocalStorage from "../../../hook/useLocalStorage";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import OneSignal from "react-onesignal";

import backgroundImage from "../../../assets/images/heroBg.png";
import Logo from "../../../assets/images/OpalRoundWeb.png";

import { Helmet, HelmetProvider } from "react-helmet-async";
import eventEmitter from "../../Events/EventEmitter.js";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [profileId, setProfileId] = useLocalStorage("profileId", "");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const customerToken = localStorage.getItem("customer_access_token");
    if (customerToken) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    OneSignal.init({ appId: "762f8fbd-aad1-4765-9410-0e091d4d8d18" }).then(
      () => {
        OneSignal.showSlidedownPrompt().then(() => {});
      }
    );
  }, []);

  const responseGoogle = (response) => {
    console.log(response);
    const userObject = jwt_decode(response.credential);
    console.log(userObject);
    if (userObject.iat) {
      let data = {
        first_name: userObject.given_name,
        last_name: userObject.family_name,
        email: userObject.email,
        google_id: userObject.jti,
        google_payload: userObject,
        profile_picture: userObject.picture,
        device: 3,
      };
      console.log(data);
      onSubmit(data);
    }
  };

  const onSubmit = () => {
    if (username.length <= 0) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (password.length <= 0) {
      toast.error("Please enter a valid password");
      return;
    }

    var data = {};
    data["device"] = 3;
    data["username"] = { 2: username };
    data["password"] = password;
    data["pushToken"] = "token";
    Http.callApi(url.customer_login, JSON.stringify(data))
      .then((response) => {
        console.log("Login response", response);
        const data = response.data.accessToken;
        const id = response.data.id;
        const authType = "customer";
        localStorage.setItem("customer_access_token", data);
        localStorage.setItem("authType", authType);
        setProfileId(id);
        eventEmitter.emit("updateUser");
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Login to your Opal Empire Account | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link rel="canonical" href={`https://opalempire.com.au/`} />
            <meta
              property="og:title"
              content={`Opal Empire | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta property="og:url" content={`https://opalempire.com.au/`} />
            <meta
              property="og:image"
              content={
                "https://opalempire.com.au/static/media/heroBg.320af6a2923a9cbb9049.png"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      <div
        className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen px-4"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <div className="flex justify-center items-center">
            <Link to={"/"}>
              <img
                src={Logo}
                className="h-32 md:h-96 w-32 md:w-96"
                alt="Opal Empire"
              />
            </Link>
          </div>

          <div className="flex justify-start items-center">
            <div className="flex flex-col bg-white rounded-2xl px-6 md:px-8 pt-8 pb-6 w-full max-w-md">
              <h4 className="text-2xl md:text-xl font-semibold">
                Login to Opal Empire
              </h4>
              <p className="font-normal mt-1 text-sm">
                Login to your Opal Empire Account
              </p>

              <div className="flex flex-row mt-3 items-center justify-center">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    responseGoogle(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  useOneTap
                />
              </div>

              <div className="flex flex-row justify-center items-center mt-3 mb-4">
                <div className="w-[30%] md:w-2/6 h-[1px] bg-lightGrey" />
                <p className="text-sm text-lightGrey font-normal mx-4">
                  Login via email
                </p>
                <div className="w-[30%] md:w-2/6 h-[1px] bg-lightGrey" />
              </div>

              <div className="flex flex-col">
                <div className="mt-1 md:mt-2 border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="email"
                    onChange={(val) => setUsername(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Email address"
                  />
                </div>

                <div className="mt-3 md:mt-2 border flex flex-row border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={(val) => setPassword(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Password"
                  />
                  <div
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="cursor-pointer"
                  >
                    <VisibilityOutlinedIcon
                      style={{ color: showPassword ? "#000" : "#a6a9b3" }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row my-3">
                <div className="flex flex-row justify-center items-center">
                  <input className="w-4 h-4" type="checkbox" />
                  <p className="ml-3">Remember Me</p>
                </div>
              </div>

              <div
                onClick={() => {
                  onSubmit();
                }}
                className="cursor-pointer w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-3"
              >
                <p className="text-white font-medium text-md md:text-lg text-center">
                  Login
                </p>
              </div>

              <Link to={"/"}>
                <p className="text-center text-opalBlue font-medium">
                  View as a Guest
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
