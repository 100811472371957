import React, { useState } from "react";
import { Link } from "react-router-dom";

import { GoHome, GoHomeFill } from "react-icons/go";
import { MdOutlineStream, MdStream } from "react-icons/md";
import { IoBrushOutline, IoBrush } from "react-icons/io5";
import { IoDiamondOutline, IoDiamond } from "react-icons/io5";
import { FaRegUserCircle, FaUserCircle } from "react-icons/fa";

const BottomNavigationBar = () => {
  const [activeTab, setActiveTab] = useState("home");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      className="flex md:hidden"
      style={{
        flexDirection: "row",
        justifyContent: "space-around",
        position: "sticky",
        paddingTop: 8,
        paddingBottom: 8,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#ffffff",
      }}
    >
      {/* Use Link instead of anchor tags */}
      <Link
        to="/"
        className={`flex flex-col text-md justify-center items-center ${
          activeTab === "home" ? "text-opalBlue" : "text-black"
        }`}
        onClick={() => handleTabClick("home")}
      >
        {activeTab === "home" ? <GoHomeFill size={20} /> : <GoHome size={20} />}
        <p className="font-medium text-xs mt-2">Home</p>
      </Link>

      <Link
        to="/podcasts"
        className={`flex flex-col text-md justify-center items-center ${
          activeTab === "streams" ? "text-opalBlue" : "text-black"
        }`}
        onClick={() => handleTabClick("streams")}
      >
        {activeTab === "streams" ? (
          <MdStream size={20} />
        ) : (
          <MdOutlineStream size={20} />
        )}
        <p className="font-medium text-xs mt-2">Streams</p>
      </Link>

      <Link
        to="/art"
        className={`flex flex-col text-md justify-center items-center ${
          activeTab === "art" ? "text-opalBlue" : "text-black"
        }`}
        onClick={() => handleTabClick("art")}
      >
        {activeTab === "art" ? (
          <IoBrush size={20} />
        ) : (
          <IoBrushOutline size={20} />
        )}
        <p className="font-medium text-xs mt-2">Art</p>
      </Link>

      <Link
        to="/jewellery"
        className={`flex flex-col text-md justify-center items-center ${
          activeTab === "jewellery" ? "text-opalBlue" : "text-black"
        }`}
        onClick={() => handleTabClick("jewellery")}
      >
        {activeTab === "jewellery" ? (
          <IoDiamond size={20} />
        ) : (
          <IoDiamondOutline size={20} />
        )}
        <p className="font-medium text-xs mt-2">Jewellery</p>
      </Link>

      <Link
        to="/profile"
        className={`flex flex-col text-md justify-center items-center ${
          activeTab === "profile" ? "text-opalBlue" : "text-black"
        }`}
        onClick={() => handleTabClick("profile")}
      >
        {activeTab === "profile" ? (
          <FaUserCircle size={20} />
        ) : (
          <FaRegUserCircle size={20} />
        )}

        <p className="font-medium text-xs mt-2">Profile</p>
      </Link>
    </div>
  );
};

export default BottomNavigationBar;
