import React, { useEffect, useState } from "react";
import OrderCard from "../../SubComponents/OrderCard";
import Http from "../../security/Http";
import url from "../../../Development.json";
import CommonLoader from "../../AppLoaders/CommonLoader";

const UserOrder = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const getOrders = () => {
    Http.callApi(url.customer_order)
      .then((response) => {
        setOrders(response.data);
        setTimeout(() => {
          setIsLoading(false);
          window.scrollTo({ behavior: "smooth", top: 0 });
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="container mx-auto min-h-svh px-4 mt-8">
          <h4 className="text-xl md:text-3xl font-medium mb-3">My Orders</h4>
          {orders?.length > 0 ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {orders.map((item, index) => (
                  <OrderCard key={`order-${index}`} item={item} />
                ))}
              </div>
            </>
          ) : (
            <div className="flex flex-col h-[75vh] w-full justify-center items-center">
              <h5 className="text-md md:text-xl text-center font-medium">
                You have not placed any orders on Opal Empire yet
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col py-16 h-[85vh] w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Orders</p>
        </div>
      )}

      <div style={{ height: 32 }} />
    </>
  );
};

export default UserOrder;
