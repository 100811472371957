import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response.js";
import CommonLoader from "../../AppLoaders/CommonLoader.jsx";
import usePersistentUUID from "../../../hook/persistentUUID.js";
import useLocalStorage from "../../../hook/useLocalStorage.js";

import { FaRegTrashAlt } from "react-icons/fa";
import GuestLogin from "../Authentication/GuestLogin.jsx";
import eventEmitter from "../../Events/EventEmitter.js";

const CartItemCard = ({ item, onDeleteItem }) => {
  const firstPhotoItem =
    item && item.item && item.item.image.find((item) => item.type === "photo");

  return (
    <>
      <div className="flex flex-row mb-3 border border-grey justify-center items-center rounded-xl px-3 md:px-4 py-3 md:py-3">
        {firstPhotoItem.type === "photo" ? (
          <img
            src={firstPhotoItem.item}
            className="w-24 md:w-32 h-24 md:h-32 object-cover rounded-xl"
            alt=""
          />
        ) : (
          <video
            autoPlay={false}
            muted
            loop
            className="w-24 h-24 object-cover rounded-xl"
          >
            <source src={firstPhotoItem.item} />
          </video>
        )}
        <div className="flex flex-col ml-4">
          <p className="font-semibold md:font-medium text-sm md:text-lg">
            {item?.item?.name ?? ""}
          </p>
          <p className="font-semibold md:font-medium text-green-700 text-sm mt-2 md:text-lg">
            ${item.price?.toFixed(2) ?? "0.00"} AUD
          </p>
        </div>
        <div style={{ flex: 1 }} />
        <div
          className="mr-2"
          onClick={() => {
            onDeleteItem(item?.item?._id ?? "");
          }}
        >
          <FaRegTrashAlt className="text-base md:text-xl" color={"#ff0000"} />
        </div>
      </div>
    </>
  );
};

const Cart = () => {
  const navigate = useNavigate();
  const deviceUUID = usePersistentUUID();
  const [profileId, setProfileId] = useLocalStorage("profileId", "");

  const [signupLoading, setSignupLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [cart, setCart] = useState([]);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const updateCart = () => {
    console.log("Profile ID: ", profileId);
    console.log("Device UUID: ", deviceUUID);
    setIsLoading(true);
    Http.callApi(url.get_cart, {
      userid: profileId,
      deviceUUID: deviceUUID,
    }).then((response) => {
      console.log("Cart Data: ", response);
      setCart(response.data);
      setTimeout(() => {
        setIsLoading(false);
        window.scrollTo({ behavior: "smooth", top: 0 });
      }, 250);
    });
  };

  const deleteCartItem = (itemId) => {
    console.log("Removing Item from Cart", itemId ?? "");
    console.log("User ID:", profileId);
    console.log("Device UUID:", deviceUUID);
    Http.callApi(url.remove_cart_item, {
      userid: profileId ?? "",
      deviceUUID: deviceUUID,
      item_id: itemId ?? "",
    })
      .then((response) => {
        console.log(response);
        updateCart();
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const loginGuest = (data) => {
    console.log("Guest Login Data:", data);
    setSignupLoading(true);
    Http.callApi(url.v2_create_guest, data).then((response) => {
      console.log(response);
      const data = response.data.accessToken;
      const id = response.data.id;
      localStorage.setItem("customer_access_token", data);
      localStorage.setItem("authType", "customer");
      setProfileId(id);
      eventEmitter.emit("updateUser");
      setSignupLoading(false);
      setTimeout(() => {
        navigate("/checkout");
      }, 250);
    });
  };

  const navigateToCheckout = () => {
    if (profileId) {
      navigate("/checkout");
    } else {
      setShowLoginModal(true);
    }
  };

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    updateCart();
  }, []);

  return (
    <>
      <GuestLogin
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(!showLoginModal)}
        onSubmit={loginGuest}
        isLoading={signupLoading}
      />
      {isLoading ? (
        <div className="flex flex-col py-16 h-[80vh] w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium text-sm md:text-xl">Updating your cart</p>
        </div>
      ) : (
        <div className="container mx-auto px-4 mt-8 h-screen">
          {cart && cart?.items?.length > 0 ? (
            <>
              <h4 className="text-lg md:text-2xl font-semibold md:font-medium mb-1 md:mb-3">
                Cart
              </h4>
              <p className="text-sm md:text-base mb-3">
                These items are currently in your cart, and ready to purchase!
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {cart?.items?.map((item, index) => {
                  return (
                    <CartItemCard
                      item={item}
                      onDeleteItem={(val) => deleteCartItem(val)}
                    />
                  );
                })}
              </div>
              <div className="flex justify-center items-center mt-4">
                <div
                  onClick={navigateToCheckout}
                  className="flex cursor-pointer w-full bg-opalBlue py-3 justify-center w-full items-center rounded-xl mb-4"
                >
                  <p className="text-white font-semibold md:font-medium text-sm md:text-base text-center">
                    Proceed to Checkout
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col h-[75vh] md:h-[70vh] justify-center items-center">
              <p className="font-semibold md:font-medium text-base md:text-xl">
                There are no items currently in your cart
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
