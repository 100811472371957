import React from "react";
import Lottie from "react-lottie-player";

import animation from "../../assets/animations/loader.json";

const CommonLoader = () => {
  return (
    <>
      <Lottie
        loop
        play
        animationData={animation}
        style={{ width: 200, height: 150 }}
      />
    </>
  );
};

export default CommonLoader;
