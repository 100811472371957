import React from "react";

import { FaRegCirclePlay } from "react-icons/fa6";

const PodcastCard = ({ podcast, onPodcastClick }) => {
  return (
    <>
      <div
        key={podcast._id}
        className="flex flex-col border rounded-2xl py-3 px-3"
      >
        <img
          src={podcast.image}
          className="rounded-xl w-full h-52 object-cover"
          alt={podcast.name}
        />
        <div className="mt-3">
          <h5 className="font-semibold text-base md:text-xl h-[52px] md:h-[56px] overflow-hidden">
            {podcast.title.substring(0, 70)}
          </h5>
          <p className="mt-2 font-normal text-sm md:text-base h-[72px] md:h-[92px]">
            {podcast.description.substring(0, 120)}...
          </p>
        </div>
        <div className="mt-3">
          <button
            onClick={() => {
              onPodcastClick(podcast);
            }}
            className="flex flex-row bg-buttonBlue justify-center items-center py-3 md:py-2.5 px-8 md:px-6 rounded-xl"
          >
            <FaRegCirclePlay size={18} className="text-opalBlue" />
            <p className="ml-2 text-opalBlue text-sm md:text-base font-semibold md:font-medium">
              Play Podcast
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default PodcastCard;
