import React, { useState, useEffect } from "react";

const CountdownTimer = ({ darkMode = false, targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).reduce((acc, interval) => {
    if (timeLeft[interval] > 0) {
      // Check if the value is greater than 0
      acc.push(
        <div
          className="flex flex-col justify-center items-center mx-4 md:mx-6"
          key={interval}
        >
          <p className="text-gold font-bold text-4xl md:text-6xl">
            {timeLeft[interval]}
          </p>
          <p
            className={
              darkMode
                ? "mt-1 font-medium text-white"
                : "mt-1 font-medium text-black"
            }
          >
            {interval.toUpperCase()}
          </p>
        </div>
      );
    }
    return acc;
  }, []);

  return (
    <div className="flex flex-row">
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};

export default CountdownTimer;
