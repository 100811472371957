import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import GoogleMapReact from "google-map-react";
import dateFormat from "dateformat";

import CommonLoader from "../../AppLoaders/CommonLoader";

import { AiOutlineHome } from "react-icons/ai";
import { IoBriefcaseOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";
import { formatCurrency } from "../../Helpers/CurrencyHelpers";

// Define a Marker component for the map
const Marker = ({ text }) => <div className="text-4xl">{text}</div>;

const OrderDetailsPage = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [center, setCenter] = useState({ lat: -34.92625, lng: 138.60081 });

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("id");
    setOrderId(orderId);

    console.log("ORDER ID IS: " + orderId);
  }, []);

  useEffect(() => {
    getOrderDetails();
  }, [orderId]);

  const getOrderDetails = () => {
    setIsLoading(true);
    Http.callApi(url.v2_get_order_details, {
      orderId: orderId,
    })
      .then((response) => {
        console.log("ORDER DATA: ", JSON.stringify(response.data));
        setOrder(response.data);
        setCenter({
          lat: response.data?.addresses?.latitude,
          lng: response.data?.addresses?.longitude,
        });
        setIsLoading(false);
      })
      .catch(console.log);
  };

  return isLoading ? (
    <>
      <div className="flex flex-col h-[80vh] w-screen overflow-y-hidden justify-center items-center">
        <CommonLoader />
        <p className="font-medium text-base md:text-xl">
          Updating Order Details
        </p>
      </div>
    </>
  ) : (
    <>
      <div className="container mx-auto px-4 pt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div>
            <h4 className="font-semibold text-base md:text-lg">Details</h4>
            <p className="text-sm md:text-lg mb-2">
              Critical Details relating to your Order
            </p>

            <div className="border border-grey rounded-2xl px-4 py-4">
              <div className="flex flex-row">
                <p className="font-semibold text-base md:text-base">Order ID</p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  {order?.order_no ?? ""}
                </p>
              </div>

              <div className="flex flex-row mt-2 md:mt-3">
                <p className="font-semibold text-base md:text-base">Vendor</p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  {order?.business?.name ?? ""}
                </p>
              </div>

              <div className="flex flex-row mt-2 md:mt-3">
                <p className="font-semibold text-base md:text-base">Status</p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  {order?.order_status === "0"
                    ? "Awaiting Vendor Acceptance"
                    : order?.order_status === "1"
                    ? "Preparing"
                    : order.order_status === "2"
                    ? "Shipped"
                    : "Order Processing"}
                </p>
              </div>

              <div className="flex flex-row mt-2 md:mt-3">
                <p className="font-semibold text-base md:text-base">
                  Tracking Number
                </p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  {order?.tracking_number ?? "Awaiting Assignment"}
                </p>
              </div>

              <div className="flex flex-row mt-2 md:mt-3">
                <p className="font-semibold text-base md:text-base">
                  Order Date
                </p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  {dateFormat(order?.order_date ?? new Date(), "dd mmm yyyy")}
                </p>
              </div>

              <div className="flex flex-row mt-2 md:mt-3">
                <p className="font-semibold text-base md:text-base">Total</p>
                <div style={{ flex: 1 }} />
                <p className="text-base md:text-base font-medium">
                  ${order?.total?.toFixed(2) ?? "0.00"}
                </p>
              </div>
            </div>

            {order?.tracking_number ? (
              <div
                onClick={() => {
                  window.location.href = `https://auspost.com.au/mypost/beta/track/details/${
                    order?.tracking_number ?? ""
                  }`;
                }}
                className="cursor-pointer mt-3 w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-4"
              >
                <p className="text-white font-semibold md:font-medium text-sm md:text-base text-center">
                  Track Shipment
                </p>
              </div>
            ) : null}
          </div>

          {/* Right Column */}
          <div className="mt-4 md:mt-0">
            <h4 className="font-semibold text-base md:text-lg">
              Delivery Address
            </h4>
            <p className="text-sm md:text-lg mb-2">
              This was the location you chose your order to be delivered to
            </p>
            <div className="border border-grey rounded-2xl px-4 py-4">
              <div className="h-[250px] w-full mt-3 text-lg">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE",
                  }}
                  defaultCenter={{
                    lat: order?.addresses?.latitude ?? -34.92625,
                    lng: order?.addresses?.longitude ?? 138.60081,
                  }}
                  options={{
                    scaleControl: false,
                    mapTypeControl: false,
                    panControl: false,
                    zoomControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                  }}
                  defaultZoom={11}
                  center={center}
                  zoom={
                    order?.addresses?.latitude && order?.addresses?.longitude
                      ? 14
                      : 11
                  }
                >
                  {order?.addresses?.latitude &&
                    order?.addresses?.longitude && (
                      <Marker
                        lat={order?.addresses?.latitude}
                        lng={order?.addresses?.longitude}
                        text="📍"
                      />
                    )}
                </GoogleMapReact>
              </div>

              <div
                key={order?.addresses?._id ?? ""}
                className="flex flex-row mt-4 md:mt-6 justify-center items-center"
              >
                <div className="bg-opalLightBlue px-3 md:px-3 py-3 md:py-3 rounded-full">
                  {order?.addresses?.address_type === 0 ? (
                    <>
                      <AiOutlineHome className="text-2xl md:text-2xl text-opalBlue" />
                    </>
                  ) : order?.addresses?.address_type === 1 ? (
                    <IoBriefcaseOutline className="text-2xl md:text-2xl text-opalBlue" />
                  ) : (
                    <SlLocationPin className="text-2xl md:text-2xl text-opalBlue" />
                  )}
                </div>
                <div className="flex flex-col ml-3">
                  <p className="font-semibold text-sm md:text-sm">
                    {order?.addresses?.address_type === 0
                      ? "Home"
                      : order?.addresses?.address_type === 1
                      ? "Work"
                      : "Other"}
                  </p>
                  <p className="font-regular text-xs md:text-base w-5/6 md:w-full">
                    {order?.addresses?.address}
                  </p>
                </div>
                <div style={{ flex: 1 }} />
              </div>
            </div>

            <h4 className="font-semibold text-base md:text-lg mt-4">
              Ordered Items
            </h4>
            <p className="text-sm md:text-lg mb-2">
              Here you'll see the items you ordered
            </p>
            <div className="border border-grey rounded-2xl px-4 py-4">
              {order && order?.items
                ? order.items.map((item, index) => {
                    return (
                      <>
                        <div className="flex flex-row mb-2" key={index}>
                          <p className="font-medium text-base">{`x${
                            item?.quantity ?? 0
                          }`}</p>
                          <p className="font-medium text-base ml-3">
                            {item?.item?.name ?? ""}
                          </p>

                          <div style={{ flex: 1 }} />
                          <p className="font-medium text-base">
                            {formatCurrency(item?.price)} AUD
                          </p>
                        </div>
                        {order?.items?.length > 1 ? (
                          <hr className="mb-3" />
                        ) : null}
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden" style={{ height: 48 }} />
    </>

    //           <div className="row mt-4">
    //             <div className="col">
    //               <h4 className="app_card_title mb-1">Ordered Items</h4>
    //               <p style={{ fontSize: 14 }}>
    //                 Here you'll see the items you ordered
    //               </p>
    //               <div className="order_detail_card flex-column">
    //                 <div className="row row-cols-1 g-4">
    //                   {orderData != null && orderData.items
    //                     ? orderData?.items.map((item, index) => {
    //                         return (
    //                           <OrderItemRow
    //                             key={index}
    //                             itemName={item.name}
    //                             itemQty={item.quantity}
    //                             itemValue={item.quantity * item.price}
    //                           />
    //                         );
    //                       })
    //                     : null}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   ) : (
    //     <></>
    //   )}
    //   ;
    // </>
  );
};

export default OrderDetailsPage;
