import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GoogleMapReact from "google-map-react";

import { AiOutlineCloseCircle, AiOutlineHome } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoBriefcaseOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";

import { fromAddress, setKey } from "react-geocode";

// Define a Marker component for the map
const Marker = ({ text }) => <div className="text-4xl">{text}</div>;

const DeliveryAddressModal = ({ onAdd, onClose }) => {
  const [address, setAddress] = useState({});
  const [poBox, setPoBox] = useState("");
  const [addressType, setAddressType] = useState(0);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [center, setCenter] = useState({ lat: -34.92625, lng: 138.60081 });

  const selectAddress = (address) => {
    console.log("Selected Address: ", JSON.stringify(address));
    setAddress(address.label);

    setKey("AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE");
    fromAddress(address.label)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        setCenter({ lat, lng });
        console.log(lat, lng);
      })
      .catch(console.error);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-[2500] flex justify-center items-center">
        <div className="bg-white px-4 md:px-8 py-4 md:py-8 z-90 mx-4 md:mx-0 rounded-2xl w-full md:w-1/3">
          <div className="flex flex-row items-center justify-center w-full mb-3">
            <p className="text-md md:text-xl font-semibold">
              Add a Delivery Address
            </p>
            <div style={{ flex: 1 }} />
            <button onClick={onClose} className="float-right text-xl font-bold">
              <AiOutlineCloseCircle size={28} />
            </button>
          </div>

          <GooglePlacesAutocomplete
            apiKey="AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE"
            selectProps={{
              onChange: selectAddress,
            }}
          />

          <div className="h-[250px] w-full mt-3 text-lg">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE",
              }}
              defaultCenter={{
                lat: latitude ?? -34.92625,
                lng: longitude ?? 138.60081,
              }}
              defaultZoom={11}
              center={center}
              zoom={latitude && longitude ? 13 : 11}
            >
              {latitude && longitude && (
                <Marker lat={latitude} lng={longitude} text="📍" />
              )}
            </GoogleMapReact>
          </div>

          <div className="mt-4 md:mt-4">
            <p className="font-medium text-sm md:text-base">
              Going to a PO Box? Enter the Number below
            </p>
            <div className="mt-2 md:mt-2 border border-grey px-2 md:px-3 py-2 md:py-3 rounded-lg">
              <input
                type={"number"}
                id="poBoxNo"
                onChange={(val) => setPoBox(val.target.value)}
                className="w-full text-md md:text-base"
                placeholder="Enter your PO Box Number (Numbers only)"
              />
            </div>
          </div>

          <div className="flex flex-row mt-4">
            <div className="flex flex-col justify-center items-center mr-3">
              <div
                onClick={() => setAddressType(0)}
                className={
                  addressType === 0
                    ? "bg-opalBlue text-white px-3 md:px-3 py-3 md:py-3 rounded-full"
                    : "bg-opalLightBlue text-opalBlue px-3 md:px-3 py-3 md:py-3 rounded-full"
                }
              >
                <AiOutlineHome className="text-2xl md:text-2xl" />
              </div>
              <p className="text-md md:text-base md:mt-2">Home</p>
            </div>

            <div className="flex flex-col justify-center items-center mr-3">
              <div
                onClick={() => setAddressType(1)}
                className={
                  addressType === 1
                    ? "bg-opalBlue text-white px-3 md:px-3 py-3 md:py-3 rounded-full"
                    : "bg-opalLightBlue text-opalBlue px-3 md:px-3 py-3 md:py-3 rounded-full"
                }
              >
                <IoBriefcaseOutline className="text-2xl md:text-2xl" />
              </div>
              <p className="text-md md:text-base md:mt-2">Work</p>
            </div>

            <div className="flex flex-col justify-center items-center mr-3">
              <div
                onClick={() => setAddressType(2)}
                className={
                  addressType === 2
                    ? "bg-opalBlue text-white px-3 md:px-3 py-3 md:py-3 rounded-full"
                    : "bg-opalLightBlue text-opalBlue px-3 md:px-3 py-3 md:py-3 rounded-full"
                }
              >
                <SlLocationPin className="text-2xl md:text-2xl" />
              </div>
              <p className="text-md md:text-base md:mt-2">Other</p>
            </div>
          </div>

          <div
            onClick={() =>
              onAdd(address, latitude, longitude, poBox, addressType)
            }
            className="flex flex-row justify-center items-center bg-opalBlue px-2 py-3 mt-6 rounded-xl"
          >
            <IoMdAddCircleOutline
              color="white"
              className="text-lg md:text-2xl my-1"
            />
            <p className="ml-3 font-medium text-base md:text-base text-white">
              Add Address
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryAddressModal;
