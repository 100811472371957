import React, { useEffect, useState } from "react";
import StoreCard from "../../SubComponents/StoreCard";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import CommonLoader from "../../AppLoaders/CommonLoader";

const Stores = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataStore, setDataStore] = useState([]);
  const storeListing = () => {
    setIsLoading(true);
    Http.callApi(url.store_listing)
      .then((response) => {
        setDataStore(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    storeListing();
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  return isLoading ? (
    <CommonLoader />
  ) : (
    <>
      <div className="stores_headerWrapper">
        <div className="stores_headerOverlay">
          <h4 className="stores_heading">Vendors</h4>
        </div>
      </div>
      {dataStore?.length > 0 ? (
        <section className="home_podcastsWrapper">
          <div className="app_container">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 mt-4">
              {dataStore.map((store, index) => {
                return <StoreCard key={store._id} item={store} />;
              })}
            </div>
            {/* <button className="home_sectionsLinkBtn">View More Stores</button> */}
          </div>
        </section>
      ) : (
        <div className="empty_product_placeholder_wrapper">
          <h5 className="display_products_msg m-0 fs-4">
            Store is not Available !
          </h5>
        </div>
      )}
    </>
  );
};

export default Stores;
