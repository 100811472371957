import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomerLayout from "../Layouts/CustomerLayout";
import CustomerRoutes from "./AppRoutes";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {CustomerRoutes.map(
          ({ path, auth, element: Component, ...value }, key) => {
            return (
              <Route
                key={key}
                path="/"
                element={<CustomerLayout auth={auth} />}
              >
                <Route exact key={key} path={path} element={Component} />
              </Route>
            );
          }
        )}
      </Routes>
    </Router>
  );
};
export default AppRouter;
