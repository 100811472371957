import React from "react";
import { NavLink } from "react-router-dom";

const WishlistProductCard = ({ key, item, onWishlistClick }) => {
  return (
    <>
      <div key={key} className="col">
        <div className="product_card card h-100">
          <NavLink
            style={{ textDecoration: "unset", color: "black" }}
            to={`/product/${item._id}`}
            state={{ all_data: item }}
          >
            <div className="ratio ratio-4x3 product_card_img_container">
              {item.image[0].type === "photo" ? (
                <img
                  src={item.image[0].item}
                  className="img-fluid"
                  alt="product"
                />
              ) : (
                <video
                  autoPlay
                  muted
                  loop
                  className="img-fluid"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                >
                  <source src={item.image[0].item} />
                </video>
              )}
            </div>
          </NavLink>

          <div className="card-body px-3 pb-4">
            <div className="product_card_title_wrapper">{item.name}</div>
            <p className="product_card_desc">{item.description}</p>
            <div className="product_card_footer">
              <span className="product_card_price_tag">
                ${item.price.toFixed(2)} AUD
              </span>
              <span className="product_card_ratings">
                {/* <RatingModule ratingPercentage={productRatingPer} /> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishlistProductCard;
