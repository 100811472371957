import React from "react";
import { Link } from "react-router-dom";

const Sitemap = () => {
  return (
    <>
      <div className="sitemap_section">
        <div className="container">
          <header className="sitemap_header">
            <h3 className="">
              <b>Opal Empire Sitemap</b>
            </h3>
          </header>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-5">
              <ul className="site-map-links">
                <li>
                  <Link to="/home" target="_blank">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="/auctions" target="_blank">
                    Auctions
                  </Link>
                </li>
                <li>
                  <Link to="/auctions/auctiondetails" target="_blank">
                    Auction Details
                  </Link>
                </li>
                <li>
                  <Link to="/podcasts" target="_blank">
                    Streams
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <ul className="site-map-links">
                <li>
                  <Link to="/stores" target="_blank">
                    Stores
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" target="_blank">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" target="_blank">
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/cart" target="_blank">
                    Shopping Cart
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <ul className="site-map-links">
                <li>
                  <Link to="/login" target="_blank">
                    Customer Login
                  </Link>
                </li>
                <li>
                  <Link to="/guest" target="_blank">
                    Sign in as a Guest
                  </Link>
                </li>
                <li>
                  <Link to="/product" target="_blank">
                    Product Detail
                  </Link>
                </li>
                <li>
                  <Link to="/chat" target="_blank">
                    Chat
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <ul className="site-map-links">
                <li>
                  <Link to="/search" target="_blank">
                    Product Search Result
                  </Link>
                </li>
                <li>
                  <Link to="/vendor" target="_blank">
                    Vendor Profile
                  </Link>
                </li>
                <li>
                  <Link to="/orders" target="_blank">
                    User Orders
                  </Link>
                </li>
                <li>
                  <Link to="/wishlist" target="_blank">
                    User Wishlist
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
