import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import CommonLoader from "../../AppLoaders/CommonLoader";

import { MdChevronRight } from "react-icons/md";
import VideoPlayer from "../../SubComponents/VideoPlayer";
import PodcastCard from "../../SubComponents/PodcastCard";

const HomePodcasts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [podcast, setPodcast] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPodcasts = () => {
    setIsLoading(true);
    Http.callApi(url.customer_view_podcasts).then((response) => {
      setPodcasts(response.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    });
  };

  useEffect(() => {
    getPodcasts();
  }, []);

  return isLoading ? (
    <div className="flex flex-col py-16 w-screen overflow-y-hidden justify-center items-center">
      <CommonLoader />
      <p className="font-medium">Updating Latest Podcasts</p>
    </div>
  ) : (
    <>
      <div className="container mx-auto px-4 px-md-0 py-8 md:py-16">
        <div className="flex flex-row justify-start items-center mb-3">
          <h1 className="font-semibold text-black text-lg md:text-xl">
            Latest Podcasts
          </h1>
          <div style={{ flex: 1 }} />
          <div className="flex flex-row justify-center items-center">
            <Link to={"/podcasts"}>
              <h4 className="font-medium text-opalBlue text-sm md:text-sm">
                View all
              </h4>
            </Link>
            <MdChevronRight className="text-opalBlue" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {podcasts.slice(0, 4).map((item) => {
            return (
              <PodcastCard
                podcast={item}
                onPodcastClick={(podcast) => {
                  setPodcast(podcast);
                  setIsModalOpen(true);
                }}
              />
            );
          })}
        </div>
      </div>
      <VideoPlayer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        podcast={podcast}
      />
    </>
  );
};

export default HomePodcasts;
