import React, { useEffect, useState } from "react";
import Http from "../../security/Http";
import url from "../../../Development.json";
import Footer from "../../Layouts/Footer";
import CommonLoader from "../../AppLoaders/CommonLoader";
import VideoPlayer from "../../SubComponents/VideoPlayer";
import PodcastCard from "../../SubComponents/PodcastCard";

const Podcasts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [podcast, setPodcast] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPodcasts = () => {
    setIsLoading(true);
    Http.callApi(url.customer_view_podcasts).then((response) => {
      console.log(response.data);
      setPodcasts(response.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
      window.scrollTo({ behavior: "smooth", top: 0 });
    });
  };

  useEffect(() => {
    document.title = `Streams | Opal Empire - A Cut Above The Rest - Buy & Sell Opal, Precious Metals & Jewellery Online`;
    getPodcasts();
  }, []);

  return (
    <>
      <section
        className="py-32 relative"
        style={{
          backgroundImage: `url(${require("../../../assets/images/img_podcast_header.webp")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay with darker background */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Ensure the content of your section is not affected by the overlay by setting it relative */}
        <div className="container mx-auto relative">
          <div className="px-4 md:px-0">
            <h1 className="text-xl md:text-4xl tracking-wide font-semibold mb-3 text-white">
              Live Streams & Podcasts
            </h1>
            {/* Example of text color adjustment */}
            <h2 className="text-lg md:text-2xl tracking-wide text-white">
              Watch our latest Podcasts, and tune in to our Opal Live streams
              all in one place!
            </h2>
          </div>
        </div>
      </section>
      {isLoading ? (
        <div className="flex flex-col py-16 h-full w-screen overflow-y-hidden justify-center items-center">
          <CommonLoader />
          <p className="font-medium">Updating Podcasts</p>
        </div>
      ) : (
        <div className="container mx-auto px-4 md:px-0 py-8 md:py-16">
          <div className="flex flex-row justify-start items-center mb-3">
            <h1 className="font-semibold text-lg md:text-xl">Podcasts</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {podcasts.map((item) => {
              return (
                <PodcastCard
                  podcast={item}
                  onPodcastClick={(podcast) => {
                    setPodcast(podcast);
                    setIsModalOpen(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <Footer />
      <VideoPlayer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        podcast={podcast}
      />
    </>
  );
};

export default Podcasts;
