import React, { useEffect } from "react";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <>
      <div className="container mx-auto px-4 mt-3">
        <h4 className="font-semibold text-2xl">Privacy Policy</h4>
        <p className="mb-2">Last updated: 6 March 2024</p>

        <div className="mt-4">
          <p className="font-regular mt-3">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="font-regular mt-3">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </div>

        <h5 className="font-semibold mt-4">Interpretation and Definitions</h5>
        <p className="font-regular mt-3">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h5 className="font-semibold mt-4">Definitions</h5>
        <p className="font-regular mt-3">
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </p>

        <p className="font-regular mt-3">
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control"
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </p>

        <p className="font-regular mt-3">
          <strong>Application</strong> means the software program provided by
          the Company downloaded by You on any electronic device, named Opal
          Empire
        </p>

        <p className="font-regular mt-3">
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to U Help Removals and
          Transport Pty. Ltd. ATF The Stavreas Family Trust, 1251 Alp St Coober
          Pedy SA 5723.
        </p>

        <p className="font-regular mt-3">
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </p>

        <p className="font-regular mt-3">
          <strong>Country</strong> refers to: South Australia, Australia
        </p>

        <p className="font-regular mt-3">
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </p>

        <p className="font-regular mt-3">
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </p>

        <p className="font-regular mt-3">
          <strong>Service</strong> refers to the Application or the Website or
          both.
        </p>

        <p className="font-regular mt-3">
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </p>

        <p className="font-regular mt-3">
          <strong>Third-party Social Media Service</strong> refers to any
          website or any social network website through which a User can log in
          or create an account to use the Service.
        </p>

        <p className="font-regular mt-3">
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </p>

        <p className="font-regular mt-3">
          <strong>Website</strong> refers to Opal Empire, accessible from
          https://opalempire.com.au
        </p>

        <p className="font-regular mt-3">
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </p>

        <h5 className="font-semibold mt-4">
          Collecting and Using Your Personal Data
        </h5>
        <p className="font-regular mt-3">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>
            <p className="font-semibold mt-3">- Email address</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- First name and last name</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Phone number</p>
          </li>
          <li>
            <p className="font-semibold mt-3">
              - Address, State, Province, ZIP/Postal code, City
            </p>
          </li>
          <li>
            <p className="font-semibold mt-3">
              - Bank account information in order to pay for products and/or
              services within the Service
            </p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Usage Data</p>
          </li>
        </ul>

        <p className="font-regular mt-3">
          When You pay for a product and/or a service via bank transfer, We may
          ask You to provide information to facilitate this transaction and to
          verify Your identity. Such information may include, without
          limitation:
        </p>

        <ul>
          <li>
            <p className="font-semibold mt-3">- Date of birth</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Passport or National ID card</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Bank card statement</p>
          </li>
          <li>
            <p className="font-semibold mt-3">
              - Other information linking You to an address
            </p>
          </li>
        </ul>

        <p className="font-regular mt-3">
          Usage Data is collected automatically when using the Service.
        </p>

        <p className="font-regular mt-3">
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>

        <p className="font-regular mt-3">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>

        <p className="font-regular mt-3">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>

        <h5 className="font-semibold mt-4">
          Information from Third-Party Social Media Services
        </h5>
        <p className="font-regular mt-3">
          The Company allows You to create an account and log in to use the
          Service through the following Third-party Social Media Services:
        </p>

        <ul>
          <li>
            <p className="font-semibold mt-3">- Google</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Facebook</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- Twitter</p>
          </li>
          <li>
            <p className="font-semibold mt-3">- LinkedIn</p>
          </li>
        </ul>

        <p className="font-regular mt-3">
          If You decide to register through or otherwise grant us access to a
          Third-Party Social Media Service, We may collect Personal data that is
          already associated with Your Third-Party Social Media Service's
          account, such as Your name, Your email address, Your activities or
          Your contact list associated with that account.
        </p>

        <p className="font-regular mt-3">
          You may also have the option of sharing additional information with
          the Company through Your Third-Party Social Media Service's account.
          If You choose to provide such information and Personal Data, during
          registration or otherwise, You are giving the Company permission to
          use, share, and store it in a manner consistent with this Privacy
          Policy.
        </p>

        <h5 className="font-semibold mt-4">
          Information Collected while Using the Application
        </h5>
        <p className="font-regular mt-3">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </p>

        <ul>
          <li>
            <p className="font-regular mt-3">
              Information regarding your location
            </p>
          </li>
        </ul>

        <p className="font-regular mt-3">
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </p>

        <p className="font-regular mt-3">
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>

        <h5 className="font-semibold mt-4">
          Tracking Technologies and Cookies
        </h5>
        <p className="font-regular mt-3">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>

        <ul>
          <li>
            <p className="font-regular mt-3">
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on Your Device. You can instruct Your browser to
              refuse all Cookies or to indicate when a Cookie is being sent.
              However, if You do not accept Cookies, You may not be able to use
              some parts of our Service. Unless you have adjusted Your browser
              setting so that it will refuse Cookies, our Service may use
              Cookies.
            </p>
          </li>

          <li>
            <p className="font-regular mt-3">
              <strong>Web Beacons.</strong> Certain sections of our Service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </p>
          </li>
        </ul>

        <p className="font-regular mt-3">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. Learn more about cookies on the Privacy Policies website
          article.
        </p>

        <p className="font-regular mt-3">
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>

        <p className="font-regular mt-3">
          <strong>Necessary / Essential Cookies</strong>
        </p>

        <p className="font-regular mt-3">
          Type: Session Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services
        </p>

        <p className="font-regular mt-3">
          <strong>Cookies Policy / Notice Acceptance Cookies</strong>
        </p>

        <p className="font-regular mt-3">
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </p>

        <p className="font-regular mt-3">
          <strong>Functionality Cookies</strong>
        </p>

        <p className="font-regular mt-3">
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </p>

        <p className="font-regular mt-3">
          <strong>Tracking and Performance Cookies</strong>
        </p>

        <p className="font-regular mt-3">
          Type: Persistent Cookies
          <br />
          Administered by: Third-Parties
          <br />
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new pages, features or new functionality of the Website to see
          how our users react to them.
        </p>

        <p className="font-regular mt-3">
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>

        <h5 className="font-semibold mt-4">Links to Other Websites</h5>
        <p className="font-regular mt-3">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p className="font-regular mt-3">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <h5 className="font-semibold mt-4">Changes to this Privacy Policy</h5>
        <p className="font-regular mt-3">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="font-regular mt-3">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>

        <p className="font-regular mt-3">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h5 className="font-semibold mt-4">Contact Us</h5>
        <p className="font-regular mt-3">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <p className="font-semibold mt-3">By email: jstavreas@gmail.com</p>
      </div>

      <div style={{ height: 48 }} />
    </>
  );
};

export default Privacy;
