import React, { useEffect, useState } from "react";
import ProductCard from "../../SubComponents/ProductCard";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { Link } from "react-router-dom";
import CommonLoader from "../../AppLoaders/CommonLoader";

import { MdChevronRight } from "react-icons/md";

const HomeProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLatestProducts = () => {
    setIsLoading(true);
    Http.callApi(url.recommanded_product).then((response) => {
      console.log(response.data);
      setProducts(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getLatestProducts();
  }, []);

  return isLoading ? (
    <div className="flex flex-col py-16 w-screen overflow-y-hidden justify-center items-center">
      <CommonLoader />
      <p className="font-medium">Updating Latest Products</p>
    </div>
  ) : (
    <div className="container mx-auto px-4 px-md-0 py-8 md:py-16">
      <div className="flex flex-row justify-start items-center mb-3">
        <h1 className="font-semibold text-lg md:text-xl">Latest Products</h1>
        <div style={{ flex: 1 }} />
        {/* <div className="flex flex-row justify-center items-center">
          <Link to={"/search"}>
            <h4 className="font-medium text-opalBlue text-sm md:text-sm">
              View More
            </h4>
          </Link>
          <MdChevronRight />
        </div> */}
      </div>
      {products && products?.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-5 gap-4">
            {products.map((item, index) => {
              return <ProductCard key={index} item={item} />;
            })}
          </div>
        </>
      ) : (
        <div className="flex py-16 w-full justify-center items-center">
          <h4 className="font-medium text-lg">
            There aren't any products available at the moment
          </h4>
        </div>
      )}
    </div>
  );
};

export default HomeProducts;
