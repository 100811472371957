import React from "react";
import { Link } from "react-router-dom";

const StoreCard = ({ item }) => {
  return (
    <>
      <Link to={`/vendor?id=${item._id}`} style={{ textDecoration: "unset" }}>
        <div
          key={item._id}
          className="bg-white border border-white rounded-2xl"
        >
          <div>
            <img
              className="h-32 w-full object-cover rounded-t-2xl"
              src={item.coverPhoto}
              alt={`${item.name ?? ""} Cover`}
            />
          </div>
          <img
            src={item.image}
            className="mt-[-38px] h-20 w-20 rounded-full border border-white mx-2 object-cover"
            alt={`${item.name} Profile`}
          />
          <h4 className="px-3 pt-3 font-semibold md:font-medium text-lg md:text-xl">
            {item.name}
          </h4>
          <h4 className="px-3 pb-3 font-normal text-sm md:text-base">
            Top Vendor
          </h4>
        </div>
      </Link>
    </>
  );
};

export default StoreCard;
