import React, { useState, useEffect } from "react";
import Http from "../security/Http";
import url from "../../Development.json";
import { errorResponse } from "../Helpers/response";

const TypesFilter = ({ onCheckedFunc, selectedTypes }) => {
  const [typeData, setTypeData] = useState([]);
  const typeListing = () => {
    Http.callApi(url.item_type_listing)
      .then((response) => {
        setTypeData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    typeListing();
  }, []);

  const TypeLabel = ({ onCheckedFunc, typeId, typeName }) => {
    return (
      <div className="filter_check_input_wrapper">
        <input
          type="checkbox"
          id={typeId}
          checked={selectedTypes.indexOf(typeName) > -1}
          onChange={() => onCheckedFunc(typeName)}
          value={typeName}
          className="filter_check_input"
        />
        <label className="filter_pill_label" htmlFor={typeId}>
          {typeName}
        </label>
      </div>
    );
  };
  return (
    <div className="filter_pill_checkinput_wrapper">
      {typeData.map((item) => {
        return (
          <TypeLabel
            key={item._id}
            onCheckedFunc={onCheckedFunc}
            typeId={item._id}
            typeName={item.name}
          />
        );
      })}
    </div>
  );
};

const ShapeFilter = ({ onCheckedFunc, selectedShapes }) => {
  const [shapeData, setShapeData] = useState([]);
  const shapeListing = () => {
    Http.callApi(url.shape_listing)
      .then((response) => {
        setShapeData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    shapeListing();
  }, []);
  const ShapeLabel = ({ onCheckedFunc, shapeId, shapeName }) => {
    return (
      <div className="filter_check_input_wrapper">
        <input
          type="checkbox"
          id={shapeId}
          checked={selectedShapes.indexOf(shapeName) > -1}
          onChange={() => onCheckedFunc(shapeName)}
          value={shapeName}
          className="filter_check_input"
        />
        <label className="filter_pill_label" htmlFor={shapeId}>
          {shapeName}
        </label>
      </div>
    );
  };
  return (
    <div className="filter_pill_checkinput_wrapper">
      {shapeData.map((item) => {
        return (
          <ShapeLabel
            key={item._id}
            onCheckedFunc={onCheckedFunc}
            shapeId={item._id}
            shapeName={item.name}
          />
        );
      })}
    </div>
  );
};

export { TypesFilter, ShapeFilter };
