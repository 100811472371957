import React from "react";

import { AiOutlineHome } from "react-icons/ai";
import { IoBriefcaseOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";

const CheckoutAddressModule = ({
  key,
  address,
  onSelect,
  selected = false,
}) => {
  return (
    <>
      <div className="flex flex-col" key={key}>
        <div
          key={address?._id ?? ""}
          onClick={() => onSelect(address)}
          className="cursor-pointer flex flex-row mt-4 md:mt-0 justify-center items-center"
        >
          <div className="bg-opalLightBlue px-3 md:px-3 py-3 md:py-3 rounded-full">
            {address.address_type === 0 ? (
              <>
                <AiOutlineHome className="text-2xl md:text-2xl text-opalBlue" />
              </>
            ) : address.address_type === 1 ? (
              <IoBriefcaseOutline className="text-2xl md:text-2xl text-opalBlue" />
            ) : (
              <SlLocationPin className="text-2xl md:text-2xl text-opalBlue" />
            )}
          </div>
          <div className="flex flex-col ml-3">
            <p className="font-semibold text-sm md:text-sm">
              {address.address_type === 0
                ? "Home"
                : address.address_type === 1
                ? "Work"
                : "Other"}
            </p>
            <p className="font-regular text-xs md:text-base w-5/6 md:w-full">
              {address.address}
            </p>
          </div>
          <div style={{ flex: 1 }} />
          <input
            key={address._id}
            checked={selected}
            onChange={(e) => {
              onSelect(address);
            }}
            className="h-5 w-5"
            style={{ cursor: "pointer", borderColor: "#000000" }}
            type="radio"
          />
        </div>
      </div>

      <hr className="my-2" />
    </>
  );
};

export default CheckoutAddressModule;
