import React from "react";

import { IoMdAddCircleOutline } from "react-icons/io";

const DeliveryAddressInitialState = ({
  isLoading,
  onAddAddress,
  addressLength = 0,
}) => {
  return (
    <>
      <div className="mt-4">
        {!isLoading && addressLength === 0 ? (
          <p className="text-center mb-4">
            You currently have no saved delivery addresses
          </p>
        ) : null}
        {!isLoading ? (
          <div
            onClick={() => onAddAddress()}
            className="flex flex-row justify-center items-center cursor-pointer"
          >
            <IoMdAddCircleOutline className="text-xl md:text-xl text-opalBlue" />
            <p className="ml-2 font-semibold text-sm md:text-base text-opalBlue">
              Add Delivery Address
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DeliveryAddressInitialState;
