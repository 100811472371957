import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ProductCard from "../../SubComponents/ProductCard";
import FilterModule from "../../SubComponents/FilterModule";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import { useLocation } from "react-router-dom";

const SearchResult = (props) => {
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [isItemsAvailable, setIsItemAvailable] = useState(false);
  const handleSearchInputOnChange = (ev) => {
    setSearchInput(ev.target.value);
  };

  const handleSearchInputOnClearSearchBtnClick = () => {
    setSearchInput("");
  };

  useEffect(() => {
    setIsItemAvailable(filterData?.length > 0 || filterData === "undefined");
  }, [filterData]);

  const serchInput = (event) => {
    if (event.keyCode === 13) {
      const serch = searchInput;
      let options = {
        search: serch,
      };
      Http.callApi(url.product_list_search, options)
        .then((response) => {
          setFilterData(response.data);
        })
        .catch((error) => {
          if (error.response) {
            errorResponse(error);
          }
        });
    }
  };

  //Filters Section Functionality in Responsive Layout
  const handleMobileFiltersOnClick = () => {
    const searchFilterCol =
      document.getElementsByClassName("search_filters_col")[0];
    searchFilterCol.classList.add("showFilter");
    document.body.style.overflow = "hidden";
  };
  const handleResponsiveFiltersOnClose = () => {
    const searchFilterCol =
      document.getElementsByClassName("search_filters_col")[0];
    searchFilterCol.classList.remove("showFilter");
    document.body.style.overflow = "auto";
  };

  const itemList = (datas) => {
    setFilterData(datas);
  };

  useEffect(() => {
    itemList();
  }, []);
  return (
    <>
      <section className="search_result_wrapper">
        <div className="search_result_header">
          <div className="app_container">
            <div className="row">
              <div className="col-lg-4 col-xl-3">
                <button
                  onClick={() => handleMobileFiltersOnClick()}
                  className="primaryLink_btn w-100 mt-3 mt-lg-0 d-flex d-lg-none"
                >
                  Filters
                </button>
              </div>
              <div className="search_input_wrapper col-lg-6 col-xl-5 order-first order-lg-0">
                <span className="search_input_IconWrapper ms-2">
                  <SearchIcon style={{ color: "#0D0E11" }} />
                </span>
                <input
                  onKeyDown={(e) => serchInput(e)}
                  className="search_input"
                  type="text"
                  value={searchInput}
                  onChange={(ev) => handleSearchInputOnChange(ev)}
                  placeholder="Search for a Product or Vendor"
                  autoComplete="off"
                />
                <button
                  onClick={() => handleSearchInputOnClearSearchBtnClick()}
                  className="search_result_search_input_clear_btn"
                >
                  <CloseIcon style={{ fontSize: "14px", color: "#EFF3F9" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="app_container" style={{ padding: "2rem 0%" }}>
          <div className="row">
            <div className="col-lg-4 col-xl-3 search_filters_col">
              <div className="search_filters_header">
                <h4 className="search_result_heading filters_heading">
                  Filters
                </h4>
                <button
                  onClick={() => handleResponsiveFiltersOnClose()}
                  className="btn btn-close btn-close-white filter_close_btn"
                ></button>
              </div>
              <FilterModule filter={searchInput} itemData={itemList} />
            </div>
            {/* <div className="search_results_wrapper"> */}
            <div className="col-lg-8 col-xl-9">
              <h4 className="search_result_heading">Search Results</h4>
              {isItemsAvailable ? (
                <div className="search_results_wrapper row row-cols-2 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-3">
                  {filterData.map((item, index) => {
                    return <ProductCard key={index} item={item} />;
                  })}
                </div>
              ) : (
                <div className="search_results_wrapper search_results_wrapper_placeholder  mx-auto">
                  {/* <EmptyProductsPlaceholder /> */}
                </div>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchResult;
