import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/OpalRoundWeb.png";

import { FaFacebook, FaInstagramSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer className="bg-opalDarkBlue pt-12 md:pt-16">
        <div className="container mx-auto px-4">
          <div className="container grid grid-cols-1 md:grid-cols-4">
            <div>
              <NavLink to="/">
                <img src={Logo} alt="" className="h-32 md:h-48" />
              </NavLink>
            </div>

            <div>
              <h5 className="font-semibold text-white mt-8 md:mt-0 text-base md:text-lg mb-5">
                Quick Links
              </h5>
              <div className="flex flex-col">
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/podcasts"
                >
                  Streams
                </NavLink>
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/art"
                >
                  Art
                </NavLink>
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/jewellery"
                >
                  Jewellery
                </NavLink>
              </div>
            </div>

            <div>
              <h5 className="font-semibold text-white mt-4 md:mt-0 text-base md:text-lg mb-5">
                Information
              </h5>
              <div className="flex flex-col">
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/aboutus"
                >
                  About Us
                </NavLink>
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/privacy"
                >
                  Privacy Policy
                </NavLink>
                <NavLink
                  className="font-normal text-sm md:text-base text-white mb-4"
                  to="/terms_conditions"
                >
                  Terms & Conditions
                </NavLink>
              </div>
            </div>

            <div>
              <div className="footer_widget">
                <h5 className="font-semibold text-white mt-4 md:mt-0 text-base md:text-lg mb-4">
                  Find us on Social Media
                </h5>

                <div className="flex flex-row mt-4">
                  <a
                    className="flex flex-row justify-center items-center"
                    href="https://www.facebook.com/groups/388295645909820"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook className="text-fbBlue h-6 w-6 rounded-full" />
                    <p className="ml-4 text-sm md:text-base text-white">
                      Join our Facebook Group
                    </p>
                  </a>
                </div>

                <div className="flex flex-row mt-8">
                  <a
                    className="flex flex-row justify-center items-center"
                    href="https://www.facebook.com/groups/388295645909820"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagramSquare className="text-instaPurple h-6 w-6 rounded-full" />
                    <p className="ml-4 text-sm md:text-base text-white">
                      Follow us on Instagram
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-16 py-6 mx-auto justify-center items-center flex flex-col md:flex-row">
            <div className="w-full justify-center items-center">
              <p className="text-white text-xs md:text-sm text-center">
                © 2020 - 2024 Opal Empire & Jewellery
              </p>
            </div>
          </div>
        </div>

        {/* <div className="flex md:hidden" style={{ height: 108 }} /> */}
      </footer>
    </>
  );
};

export default Footer;
