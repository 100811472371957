import React, { useState } from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";
import usePersistentUUID from "../../../hook/persistentUUID";
import SmallerLoading from "../../AppLoaders/SmallerLoading";

const GuestLogin = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const deviceUUID = usePersistentUUID();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const submitData = () => {
    const data = {
      first_name: firstName.toString(),
      last_name: lastName.toString(),
      email: email.toString(),
      deviceUUID: deviceUUID.toString(),
    };

    onSubmit(data);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[2500] flex justify-center items-center">
      <div className="bg-white px-4 md:px-10 py-4 md:py-8 z-90 mx-4 md:mx-0 rounded-2xl w-full md:w-1/3">
        <div className="flex flex-row items-center justify-center w-full mb-2 md:mb-1">
          <p className="text-lg md:text-lg font-semibold md:font-medium">
            Guest Checkout
          </p>
          <div style={{ flex: 1 }} />
          <button onClick={onClose} className="float-right text-xl font-bold">
            <AiOutlineCloseCircle className="text-2xl md:text-2xl" />
          </button>
        </div>
        <p className="flex text-sm md:text-base font-normal mb-3">
          Please enter the below details to continue with your order
        </p>

        <div className="mt-4">
          <div className="flex flex-col">
            <p className="mt-3 md:mt-2 text-sm">First Name</p>
            <div className="mt-1 border border-grey px-2 md:px-3 py-2 md:py-3 rounded-lg">
              <input
                type={"text"}
                id="firstName"
                onChange={(val) => setFirstName(val.target.value)}
                className="w-full text-md md:text-base"
                placeholder="First Name"
              />
            </div>

            <p className="mt-3 md:mt-2 text-sm">Last Name</p>
            <div className="mt-1 border flex flex-row border-grey px-2 md:px-3 py-2 md:py-3 rounded-lg">
              <input
                type={"text"}
                id="lastName"
                onChange={(val) => setLastName(val.target.value)}
                className="w-full text-md md:text-base"
                placeholder="Last Name"
              />
            </div>

            <p className="mt-3 md:mt-2 text-sm">Email Address</p>
            <div className="mt-1 border flex flex-row border-grey px-2 md:px-3 py-2 md:py-3 rounded-lg">
              <input
                type={"email"}
                id="email"
                onChange={(val) => setEmail(val.target.value)}
                className="w-full text-md md:text-base"
                placeholder="Email Address"
              />
            </div>
          </div>

          {!isLoading ? (
            <div
              onClick={submitData}
              className="cursor-pointer w-full bg-opalBlue py-3 justify-center items-center rounded-xl mt-4"
            >
              <p className="text-white font-medium text-sm md:text-sm text-center">
                Continue to Checkout
              </p>
            </div>
          ) : (
            <>
              <div className="flex mt-2 justify-center items-center">
                <SmallerLoading />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestLogin;
