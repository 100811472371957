import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ item }) => {
  return (
    <>
      {item ? (
        <Link to={`/product?id=${item._id}`}>
          <div key={item._id} className="border border-grey rounded-xl">
            <img
              src={item.image[0].item}
              className="rounded-xl h-96 md:h-56 w-full object-cover"
              alt={`${item.name} on Opal Empire`}
            />
            <div className="px-3 pt-3">
              <div className="font-medium text-base md:text-lg h-[64px] md:h-[72px]">
                {item.name}
              </div>
              <div className="font-semibold text-base md:text-lg text-opalBlue pb-3 tracking-wide">
                <span className="">${item.price.toFixed(2)} AUD</span>
              </div>
            </div>
          </div>
        </Link>
      ) : null}
    </>
  );
};

export default ProductCard;
