import React, { useEffect, useState } from "react";
import WishlistProductCard from "../../SubComponents/WishlistProductCard";

import Http from "../../security/Http";
import url from "../../../Development.json";
import CommonLoader from "../../AppLoaders/CommonLoader";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `My Wishlist | Opal Empire - A Cut Above The Rest - Buy & Sell Opal, Precious Metals & Jewellery Online`;
    getUserWishlist();
  }, []);

  const getUserWishlist = () => {
    console.log("Getting Wishlist");
    Http.callApi(url.customer_view_wishlist)
      .then((response) => {
        console.log(response);
        setWishlist(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tapWishlist = (item) => {
    const itemId = { item_id: item._id };
    Http.callApi(url.customer_add_wishlist, itemId)
      .then((response) => {
        console.log(response.data);
        getUserWishlist();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return !isLoading ? (
    <>
      <section className="container">
        {wishlist.length > 0 ? (
          <>
            <h4 className="font-medium">Wishlist</h4>
            <div className="">
              <div className="grid grid-cols-1 gap-4">
                {wishlist.map((item, index) => (
                  <WishlistProductCard
                    key={index}
                    item={item.items[0]}
                    onWishlistClick={tapWishlist}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col h-[75vh] w-screen justify-center items-center">
            <h5 className="text-md md:text-xl text-center font-medium">
              You have no items in your wishlist yet
            </h5>
          </div>
        )}
      </section>
    </>
  ) : (
    <>
      <div className="flex flex-col h-[80vh] overflow-y-hidden justify-center items-center">
        <CommonLoader />
        <p className="font-medium">Updating your Wishlist</p>
      </div>
    </>
  );
};

export default Wishlist;
