import React, { useEffect } from "react";
import { useState } from "react";
import Logo from "../../../assets/images/OpalEmpireNewLogo.png";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import Http from "../../security/Http.js";
import url from "../../../Development.json";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [recoveryStep, setRecoveryStep] = useState(1);

  const [email, setEmail] = useState({});
  const [token, setToken] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const customerToken = localStorage.getItem("customer_access_token");
    const vendorToken = localStorage.getItem("vendor_access_token");
    if (customerToken) {
      navigate("/");
    } else if (vendorToken) {
      navigate("/vendor");
    }
  }, []);

  const handleRecoveryEmailOnSubmit = (data) => {
    data["username"] = { 2: data["email"] };
    setEmail(data["username"]);
    data["device"] = 3;
    Http.callApi(url.user_get_otp, JSON.stringify(data))
      .then((response) => {
        successResponse("Your One-Time Passcode has been sent to your Email");
        setRecoveryStep(2);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const RecoveryStep2 = (props) => {
    const handleOTPOnSubmit = (data) => {
      let obj = {
        otp:
          data["otc-1"] +
          "" +
          data["otc-2"] +
          "" +
          data["otc-3"] +
          "" +
          data["otc-4"] +
          "" +
          data["otc-5"] +
          "" +
          data["otc-6"],
        username: props.emailData,
        device: "3",
      };
      Http.callApi(url.user_verify_otp, JSON.stringify(obj))
        .then((response) => {
          successResponse("Cool, it's you! Now lets reset your password!");
          setToken(response.data.token);
          setRecoveryStep(3);
        })
        .catch((error) => {
          if (error.response) {
            // errorResponse(error);
            errorResponse("Invalid OTP.");
          }
        });
    };
    let digitValidate = function (ele) {
      if (ele.target.value.length > 1) {
        ele.target.value = " ";
      }
    };

    let tabChange = function (val) {
      let ele = document.querySelectorAll("input");
      if (ele[val - 1].value != "") {
        ele[val].focus();
      } else if (ele[val - 1].value == "") {
        ele[val - 2].focus();
      }
    };

    return (
      <>
        <p className="app_text_sm mb-3 text-center">
          Enter the 6 digit verification code that was sent to your email
        </p>
        <form
          className="otc mx-auto"
          name="one-time-code"
          autoComplete="off"
          onSubmit={handleOTPOnSubmit}
        >
          <fieldset className="d-flex flex-column">
            <label htmlFor="otc-1">Number 1</label>
            <label htmlFor="otc-2">Number 2</label>
            <label htmlFor="otc-3">Number 3</label>
            <label htmlFor="otc-4">Number 4</label>
            <label htmlFor="otc-5">Number 5</label>
            <label htmlFor="otc-6">Number 6</label>

            <div>
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(1)}
                maxlength="1"
                id="otc-1"
              />
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(2)}
                maxlength="1"
                id="otc-2"
              />
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(3)}
                maxlength="1"
                id="otc-3"
              />
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(4)}
                maxlength="1"
                id="otc-4"
              />
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(5)}
                maxlength="1"
                id="otc-5"
              />
              <input
                className="forget_number"
                type="number"
                pattern="[0-9]*"
                onInput={(e) => digitValidate(e)}
                onKeyUp={() => tabChange(6)}
                maxlength="1"
                id="otc-6"
              />
            </div>
          </fieldset>
          <input
            type="submit"
            className="primaryLink_btn ms-auto mt-4"
            value="Continue"
          />
        </form>
      </>
    );
  };

  const RecoveryStep3 = (props) => {
    const handleChangePasswordSubmit = (data) => {
      data["token"] = props.tokenData;
      data["device"] = 3;
      if (data["password"] != data["confirmPassword"]) {
        errorResponse("Passwords don't match. ");
      } else {
        Http.callApi(url.user_change_password, JSON.stringify(data))
          .then((response) => {
            successResponse(
              "Your Password has been successfully reset! Now lets head back to the Login page and get ordering!"
            );
            setTimeout(() => {
              navigate("/login");
            }, 2500);
          })
          .catch((error) => {
            if (error.response) {
              errorResponse(error);
            }
          });
      }
    };

    return (
      <>
        <form
          className="mt-3 d-flex flex-column"
          autoComplete="off"
          onSubmit={handleChangePasswordSubmit}
        >
          <p className="app_text_sm mb-2">New Password</p>
          <div className="app_textInputWrapper mb-4">
            <input
              type="password"
              id="password"
              className="app_textInput"
              placeholder=" "
            />
            <label htmlFor="password" className="appInput_Placeholder">
              Password
            </label>
          </div>
          <p className="app_text_sm mb-2">Confirm new Password</p>
          <div className="app_textInputWrapper">
            <input
              type="password"
              id="confirmPassword"
              className="app_textInput"
              placeholder=" "
            />
            <label htmlFor="confirmPassword" className="appInput_Placeholder">
              Password
            </label>
          </div>
          <input
            type="submit"
            className="primaryLink_btn"
            value="Change Password"
          />
        </form>
      </>
    );
  };

  return (
    <div
      className="app_img_bg"
      style={{ height: "90vh", overflowY: "hidden", overflowX: "hidden" }}
    >
      <div className="account_recovery_box">
        <div className="recovery_box_logo_container">
          <img src={Logo} alt="logo" />
        </div>
        <h4 className="app_heading_sm mx-auto mb-3">Recover your Account</h4>
        {recoveryStep === 1 && (
          <>
            <form autoComplete="off" onSubmit={handleRecoveryEmailOnSubmit}>
              <p className="app_text text-center mb-3">
                For security purposes, we need to ensure it's you trying to sign
                in.
              </p>
              <p className="app_text_sm mb-4 text-center">
                Enter your registered email address below to receive a
                verification code to change your password
              </p>
              <div className="app_textInputWrapper mb-4">
                <input
                  type="email"
                  id="email"
                  className="app_textInput"
                  placeholder=" "
                />
                <label htmlFor="email" className="appInput_Placeholder">
                  Email address
                </label>
              </div>
              <input
                type="submit"
                className="primaryLink_btn w-100 ms-auto"
                value="Submit"
              />

              {/* <button className='primaryLink_btn w-25 ms-auto' onClick={() => handleRecoveryEmailOnSubmit()}>Submit</button> */}
            </form>
          </>
        )}
        {recoveryStep === 2 && <RecoveryStep2 emailData={email} />}
        {recoveryStep === 3 && <RecoveryStep3 tokenData={token} />}
      </div>
    </div>
  );
};

export default ForgetPassword;
