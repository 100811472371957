import React from "react";
import AppRouter from "./Components/AppRouter/AppRouter";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";

const App = () => {
  return (
    <GoogleOAuthProvider clientId="212423952363-c50bo1ac9m88lb2drcl59jsfndbq4c1i.apps.googleusercontent.com">
      <AppRouter />
    </GoogleOAuthProvider>
  );
};

export default App;
