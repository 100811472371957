import React, { useEffect, useState } from "react";
import CheckoutAddressModule from "./CheckoutAddressModule";

import GoogleMapReact from "google-map-react";

// Define a Marker component for the map
const Marker = ({ text }) => <div className="text-4xl">{text}</div>;

const CheckoutAddressSelectedState = ({ selectedAddress, onAddressChange }) => {
  const [center, setCenter] = useState({ lat: -34.92625, lng: 138.60081 });

  useEffect(() => {
    console.log(selectedAddress);
    setCenter({
      lat: selectedAddress?.latitude,
      lng: selectedAddress?.longitude,
    });
  }, [selectedAddress]);

  return (
    <>
      <CheckoutAddressModule
        address={selectedAddress}
        onSelect={onAddressChange}
        selected={true}
      />
      <div className="h-[250px] w-full mt-3 text-lg">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyC3HYY9ocpc-ev6qSwzGYYSlVH8U65VIeE",
          }}
          defaultCenter={{
            lat: selectedAddress?.latitude ?? -34.92625,
            lng: selectedAddress?.longitude ?? 138.60081,
          }}
          options={{
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            rotateControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
          defaultZoom={11}
          center={center}
          zoom={
            selectedAddress?.latitude && selectedAddress?.longitude ? 14 : 11
          }
        >
          {selectedAddress?.latitude && selectedAddress?.longitude && (
            <Marker
              lat={selectedAddress?.latitude}
              lng={selectedAddress?.longitude}
              text="📍"
            />
          )}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default CheckoutAddressSelectedState;
