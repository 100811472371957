import React, { useEffect, useState } from "react";
import Http from "../../security/Http.js";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response.js";
import uuid from "react-uuid";
import { formatCurrency } from "../../Helpers/CurrencyHelpers.js";
import CommonLoader from "../../AppLoaders/CommonLoader.jsx";
import usePersistentUUID from "../../../hook/persistentUUID.js";
import useLocalStorage from "../../../hook/useLocalStorage.js";

const CheckoutSummary = ({
  selectedAddress,
  shippingCost,
  selectedDelivery,
  selectedPayment,
}) => {
  const deviceUUID = usePersistentUUID();
  const [profileId] = useLocalStorage("profileId", "");

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [cart, setCart] = useState({});

  useEffect(() => {
    setTimeout(() => {
      runCalculations();
    }, 250);
  }, [selectedAddress, shippingCost, selectedPayment]);

  const getCart = () => {
    Http.callApi(url.get_cart, {
      userId: profileId,
      deviceUUID: deviceUUID,
    })
      .then((response) => {
        console.log("CART RESPONSE: ", response.data);
        setCart(response.data);
      })
      .catch((error) => {
        if (error.response) {
        }
      });
  };

  useEffect(() => {
    getCart();
  }, []);

  const runCalculations = () => {
    console.log("SELECTED ADDRESS: ", selectedAddress.address);
    console.log("DELIVERY FEE: ", shippingCost);
    console.log("PAYMENT TYPE: ", selectedPayment?.paymentMethodId ?? "stripe");
    setPaymentData(null);
    Http.callApi(url.calculate_order, {
      address: selectedAddress.address,
      delivery_fee: shippingCost,
      payment_type: selectedPayment?.paymentMethodId ?? "stripe",
    })
      .then((response) => {
        console.log("CALCULATE ORDER RESPONSE: ", response);
        setPaymentData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const placeOrder = () => {
    setLoadingPayment(true);
    Http.callApi(url.create_web_app_order, {
      addressId: selectedAddress._id,
      delivery_fee: paymentData.delivery_fee,
      payment_processing_fee: paymentData.payment_processing_fee,
      gst: paymentData.gst,
      total: paymentData.total,
      payment_type: selectedPayment.paymentTypeId,
    })
      .then((response) => {
        console.log("CREATE WEB APP ORDER RESPONSE: ", response);

        // Details to find the order
        localStorage.setItem("cart_id", cart._id);
        localStorage.setItem("customer_id", profileId);
        localStorage.setItem(
          "payment_intent_id",
          response.data.session.payment_intent
        );

        // Required Data
        localStorage.setItem("address_id", selectedAddress._id);
        localStorage.setItem(
          "payment_type",
          selectedPayment.paymentTypeId ?? "stripe"
        );
        localStorage.setItem("delivery_code", selectedDelivery);

        // Pricing Data
        localStorage.setItem("delivery_fee", paymentData.delivery_fee);
        localStorage.setItem("total", paymentData.total);
        localStorage.setItem("subtotal", paymentData.subtotal);
        localStorage.setItem("gst", paymentData.gst);
        localStorage.setItem(
          "processing_fee",
          paymentData.payment_processing_fee
        );
        setTimeout(() => {
          setLoadingPayment(false);
          window.location.href = response.data.session.url;
        }, 500);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const CheckoutSummeryRow = ({ label, value, larger = false }) => {
    return (
      <div className="flex flex-row mb-2" key={uuid()}>
        <p
          className={
            larger
              ? "font-semibold text-base md:text-xl text-opalBlue"
              : "font-medium text-sm md:text-base"
          }
        >
          {label}
        </p>
        <div style={{ flex: 1 }} />
        <p
          className={
            larger
              ? "font-semibold text-base md:text-xl text-opalBlue"
              : "font-medium text-sm md:text-base"
          }
        >
          {value}
        </p>
      </div>
    );
  };

  return (
    <>
      <div>
        {paymentData ? (
          <>
            <div className="border border-grey rounded-xl px-4 py-4">
              {cart &&
                cart?.items?.map((item, index) => {
                  return (
                    <>
                      <div className="flex flex-row mb-2" key={index}>
                        <p className="font-medium text-sm md:text-base">{`x${
                          item?.quantity ?? 0
                        }`}</p>
                        <p className="font-medium text-sm md:text-base ml-3">
                          {item?.item?.name ?? ""}
                        </p>

                        <div style={{ flex: 1 }} />
                        <p className="font-medium text-sm md:text-base">
                          {formatCurrency(item?.price)} AUD
                        </p>
                      </div>
                      <hr className="mb-3" />
                    </>
                  );
                })}
              <CheckoutSummeryRow
                label="Subtotal"
                value={`${formatCurrency(paymentData.subtotal)} AUD`}
              />

              <CheckoutSummeryRow
                label="Delivery"
                value={`${formatCurrency(paymentData.delivery_fee)} AUD`}
              />
              <CheckoutSummeryRow
                label="Payment Processing Fee"
                value={`${formatCurrency(
                  paymentData.payment_processing_fee
                )} AUD`}
              />
              {paymentData.gst > 0 ? (
                <CheckoutSummeryRow
                  label="Goods & Services Tax"
                  value={`${formatCurrency(paymentData.gst)} AUD`}
                />
              ) : null}

              <CheckoutSummeryRow
                label="Total"
                value={`${formatCurrency(paymentData.total)} AUD`}
                larger={true}
              />
            </div>

            {!loadingPayment ? (
              <div
                onClick={placeOrder}
                className="flex cursor-pointer w-full bg-opalBlue py-3 justify-center w-full items-center rounded-xl mt-2 mb-2"
              >
                <p className="text-white font-semibold md:font-medium text-sm md:text-base text-center">
                  Pay & Place Order
                </p>
              </div>
            ) : (
              <>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CommonLoader />
                  <p className="font-medium text-lg md:text-xl">
                    Processing...
                  </p>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonLoader />
              <p className="font-medium text-lg md:text-xl">
                Updating Order Price
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CheckoutSummary;
