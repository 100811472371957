import React, { useEffect, useState } from "react";
import CommonLoader from "../../AppLoaders/CommonLoader";

import opalEliteLogo from "../../../assets/images/opalelite-weblogo.png";
import CountdownTimer from "../../SubComponents/CountdownTimer";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import Footer from "../../Layouts/Footer";

import { FaCheck, FaChevronDown } from "react-icons/fa";

const SingleGiveaway = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`GEMMASTA GMP2 Cutting Machine Giveaway | OPAL EMPIRE ELITE CLUB | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link
              type="image/png"
              rel="icon"
              href="https://opalempire.com.au/elite-favicon.ico"
            />
            <link
              rel="canonical"
              href={`https://opalempire.com.au/giveaway/gemmasta-gmp2`}
            />
            <meta
              property="og:title"
              content={`GEMMASTA GMP2 Cutting Machine Giveaway | OPAL EMPIRE ELITE CLUB | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://opalempire.com.au/giveaway/gemmasta-gmp2`}
            />
            <meta
              property="og:image"
              content={
                "https://opalempire.com.au/gemmasta-official-desktop.png"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>
      {isLoading ? (
        <>
          <div className="flex flex-col h-screen w-screen overflow-y-hidden justify-center items-center">
            <CommonLoader />
            <p className="font-medium text-base md:text-xl">
              Updating Giveaway Details
            </p>
          </div>
        </>
      ) : (
        <>
          <header className="bg-opalDarkBlue py-0 md:py-2 px-0 md:px-4 w-screen md:w-full">
            <div className="container mx-auto px-2 flex justify-center items-center">
              <img
                className="h-38 md:h-56"
                src={opalEliteLogo}
                alt={"Opal Elite Logo"}
              />
            </div>
          </header>
          <div className="mx-auto overflow-hidden flex flex-col justify-center items-center pt-8">
            {/* Giveaway Timings */}
            <p className="font-semibold text-2xl md:text-3xl">
              GIVEAWAY ENDS IN...
            </p>

            <div className="pt-4">
              <CountdownTimer targetDate={"2024-03-31T09:00:00"} />
            </div>

            <a className="px-4 md:px-0 mt-6 w-full md:w-1/2" href="#packages">
              <div className="cursor-pointer z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                <p className="text-center text-lg md:text-2xl text-white font-semibold tracking-wide py-3 md:py-3">
                  BECOME A MEMBER NOW
                </p>
              </div>
            </a>

            {/* Giveaway Imagery */}
            <div className="pt-8">
              <img
                className="hidden md:flex"
                src={require("../../../assets/images/gemmasta-official-desktop.png")}
                alt="Giveaway"
              />

              <div className="relative justify-center items-center">
                <img
                  className="flex md:hidden relative"
                  src={require("../../../assets/images/gemmasta-official-mobile.png")}
                  alt="Giveaway"
                />

                <div className="px-4 w-full mx-auto md:w-1/2 absolute bottom-4 left-0 right-0">
                  <a href="#packages">
                    <div className="cursor-pointer z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                      <p className="text-center text-lg md:text-2xl text-white font-semibold tracking-wide py-3 md:py-4">
                        ENTER THE DRAW
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              {/* Giveaway Packages */}
              <div
                id="packages"
                className="container mx-auto justify-center items-center px-4 pt-16 mb-8"
              >
                <p className="text-center mx-auto w-full px-4 md:px-0 md:w-1/2 text-xl font-semibold pb-6">
                  Purchase a package below for your chance to win this GEMMASTA
                  Grinder for as little as a $15.95 Bronze Package!
                </p>

                <div className="mt-8 flex flex-row">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    {/* Bronze Package */}
                    <div className="bg-bronze px-4 py-4 rounded-2xl justify-center items-center">
                      <p className="text-center text-2xl tracking-wide font-semibold text-white">
                        BRONZE
                      </p>
                      <p className="mt-1 text-5xl font-semibold text-white text-center">
                        1
                      </p>
                      <p className="text-xl font-semibold text-white text-center mt-1">
                        ENTRY
                      </p>

                      <div className="bg-white px-4 py-4 mt-3 rounded-xl">
                        <div className="flex flex-col">
                          <p className="text-center">
                            1 Entry into this Giveaway
                          </p>
                          <hr className="mt-1" />

                          <div className="mt-4 md:mt-33 flex flex-col justify-center items-center text-center">
                            <p className="font-semibold text-2xl">$15.95 AUD</p>
                            <p className="text-xs text-center">
                              Single Payment
                            </p>
                          </div>

                          <div className="bg-bronze rounded-lg py-2 px-8 justify-center mt-3">
                            <p className="text-white tracking-wide text-center font-semibold">
                              SELECT
                            </p>
                          </div>

                          <div className="flex flex-col justify-center items-center">
                            <p className="text-sm mt-2">Billed Instantly</p>
                            <p className="text-sm">Single Purchase</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Silver Package */}
                    <div className="bg-silver px-4 py-6 rounded-2xl justify-center items-center">
                      <p className="text-center text-2xl tracking-wide font-semibold text-white">
                        SILVER
                      </p>
                      <p className="mt-1 text-5xl font-semibold text-white text-center">
                        1
                      </p>
                      <p className="text-xl font-semibold text-white text-center mt-1">
                        ENTRY
                      </p>

                      <div className="bg-white min-h-max px-4 py-4 mt-3 rounded-xl">
                        <div className="flex flex-col">
                          <p className="text-center">
                            10% off Opal Empire Merchandise
                          </p>
                          <hr className="mt-1" />

                          <p className="text-center mt-2">
                            Access to 1 Going Underground with Opal Empire
                            episode per month
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Receive Instant Updates on new Giveaways
                          </p>
                          <hr className="mt-2" />

                          <div className="mt-4 md:mt-14 flex flex-col justify-center items-center text-center">
                            <p className="font-semibold text-2xl">$29.95 AUD</p>
                            <p className="text-xs text-center">
                              Monthly Subscription
                            </p>
                          </div>

                          <div className="bg-silver rounded-lg py-2 px-8 justify-center mt-3">
                            <p className="text-white tracking-wide text-center font-semibold">
                              SELECT
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-sm mt-2">Billed Monthly</p>
                            <p className="text-sm">Cancel Anytime</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Gold Package */}
                    <div className="bg-gold px-4 py-6 rounded-2xl justify-center items-center">
                      <p className="text-center text-2xl tracking-wide font-semibold text-white">
                        GOLD
                      </p>
                      <p className="mt-1 text-5xl font-semibold text-white text-center">
                        3
                      </p>
                      <p className="text-xl font-semibold text-white text-center mt-1">
                        ENTRIES
                      </p>

                      <div className="bg-white px-4 py-4 mt-3 rounded-xl">
                        <div className="flex flex-col">
                          <p className="text-center">
                            15% off Opal Empire Merchandise
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Access to 1 Going Underground with Opal Empire
                            episode per month
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Access to In-App Gamification Discounts
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Receive Instant Updates on new Giveaways
                          </p>
                          <hr className="mt-2" />

                          <div className="mt-4 md:mt-2 flex flex-col justify-center items-center text-center">
                            <p className="font-semibold text-2xl">$49.95 AUD</p>
                            <p className="text-xs text-center">
                              Monthly Subscription
                            </p>
                          </div>

                          <div className="bg-gold rounded-lg py-2 px-8 justify-center mt-3">
                            <p className="text-white tracking-wide text-center font-semibold">
                              SELECT
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-sm mt-2">Billed Monthly</p>
                            <p className="text-sm">Cancel Anytime</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Elite Package */}
                    <div className="bg-platinum px-4 py-6 rounded-2xl justify-center items-center">
                      <p className="text-center text-2xl tracking-wide font-semibold text-white">
                        ELITE
                      </p>
                      <p className="mt-1 text-5xl font-semibold text-white text-center">
                        8
                      </p>
                      <p className="text-xl font-semibold text-white text-center mt-1">
                        ENTRIES
                      </p>

                      <div className="bg-white px-4 py-4 mt-3 rounded-xl">
                        <div className="flex flex-col">
                          <p className="text-center">
                            20% off Opal Empire Merchandise
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Access to 1 Going Underground with Opal Empire
                            episode per month
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Access to In-App Gamification Discounts
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Receive Instant Updates on new Giveaways
                          </p>
                          <hr className="mt-2" />

                          <p className="text-center mt-2">
                            Access to Partner Discounts
                          </p>
                          <hr className="mt-2" />

                          <div className="mt-4 md:mt-2 flex flex-col justify-center items-center text-center">
                            <p className="font-semibold text-2xl">$99.00 AUD</p>
                            <p className="text-xs text-center">
                              Monthly Subscription
                            </p>
                          </div>

                          <div className="bg-platinum rounded-lg py-2 px-8 justify-center mt-3">
                            <p className="text-white tracking-wide text-center font-semibold">
                              SELECT
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-sm mt-2">Billed Monthly</p>
                            <p className="text-sm">Cancel Anytime</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Secure Checkout Section */}
              <div className="mt-12 md:mt-16 mb-16 md:mb-12">
                <div className="container mx-auto grid grid-cols-1">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold">Instant and Secure Checkout</p>
                    <img
                      className="h-12 mt-2"
                      src={require("../../../assets/images/apple-google-pay.png")}
                      alt="Apple & Google Pay"
                    />
                  </div>
                </div>
              </div>

              {/* Download the App */}
              <div className="mb-12 bg-opalDarkBlue px-4 pt-8 pb-16 md:pb-8">
                <div className="container px-4 mx-auto grid grid-cols-1 md:grid-cols-2">
                  <div className="mx-8 flex flex-col items-center md:items-start justify-center">
                    <img
                      className="h-48 md:h-56"
                      src={require("../../../assets/images/OpalDualBadges.png")}
                      alt="Opal Empire Elite Club"
                    />
                    <p className="font-semibold text-white text-2xl">
                      Become a Gold, or Elite member today!
                    </p>

                    <p className="font-medium text-base text-white mt-4">
                      Join the Opal Empire Elite Club to gain access to
                      exclusive In-App Discounts & Features.
                    </p>

                    <p className="font-medium text-base text-white mt-4">
                      Be the first to explore exclusive items on Opal Empire,
                      and get access to our latest and greatest features.
                    </p>

                    <a className="w-full" href="#packages">
                      <div className="mt-4 z-[200] bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                        <p className="text-center text-base md:text-base text-white font-semibold tracking-wide py-3 md:py-3">
                          BECOME A MEMBER TODAY
                        </p>
                      </div>
                    </a>
                  </div>

                  <div className="flex mt-12 md:mt-0 flex-col justify-center items-center">
                    <img
                      className="h-[55vh]"
                      src={require("../../../assets/images/OpalElite-Phone.png")}
                      alt="Opal Empire Elite Club"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4 mt-4 mb-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col justify-start items-start">
                <p className="text-2xl font-semibold md:font-medium tracking-wide text-center w-full">
                  Draw Details
                </p>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Open to everyone worldwide!
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Winner announced 20th of April at 8:30PM via Opal Live
                    (In-App)
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Prizes will be drawn using our Automated Digital System
                  </p>
                </div>

                <div className="mt-6 flex flex-row justify-center items-center">
                  <FaCheck />
                  <p className="ml-2 text-sm md:text-base font-medium">
                    The winner will be contacted immediately by phone.
                  </p>
                </div>
              </div>

              <div className="flex mt-8 md:mt-0 flex-col justify-start items-start">
                <p className="text-2xl font-semibold md:font-medium tracking-wide text-center w-full">
                  FAQ
                </p>

                <div className="mt-12 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Where is this promotion valid?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Is there any ongoing payments?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Do I receive Entries?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    How do we receive the prize?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    If I win, how long before I receive my prize?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Do I need to watch the live or answer my phone to win?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>

                <div className="mt-4 border border-grey w-full justify-center items-center px-4 py-3 rounded-xl flex flex-row">
                  <p className="ml-2 text-sm md:text-base font-medium">
                    Where can I watch the draw?
                  </p>
                  <div style={{ flex: 1 }} />
                  <FaChevronDown />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-opalDarkBlue">
            <div className="container flex flex-col justify-center items-center mx-auto px-4 py-12 md:py-8">
              <p className="text-white text-2xl font-semibold">
                Still haven't got your entries?
              </p>

              <p className="text-white text-2xl font-semibold">
                Don't worry, you've still got time.
              </p>

              <p className="text-white text-2xl font-semibold">
                But it won't last much longer.
              </p>

              <div className="mt-6">
                <CountdownTimer
                  darkMode={true}
                  targetDate={"2024-03-31T09:00:00"}
                />
              </div>

              <a className="w-full md:w-1/2" href="#packages">
                <div className="mt-12 z-[200] mx-auto bg-opalBlue hover:bg-platinum transition-all rounded-xl">
                  <p className="text-center text-base md:text-base text-white font-semibold tracking-wide py-3 md:py-3">
                    GET MY ENTRIES NOW
                  </p>
                </div>
              </a>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default SingleGiveaway;
