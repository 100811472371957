import React, { useState, useEffect } from "react";
import { TypesFilter, ShapeFilter } from "./Filters";
import Http from "../security/Http";
import url from "../../Development.json";
import { errorResponse } from "../Helpers/response";

const FilterModule = (props) => {
  const [filterMinPrice, setFilterMinPrice] = useState("");
  const [filterMaxPrice, setFilterMaxPrice] = useState("");
  const [filterMinLength, setFilterMinLength] = useState("");
  const [filterMaxLength, setFilterMaxLength] = useState("");
  const [filterMinWidth, setFilterMinWidth] = useState("");
  const [filterMaxWidth, setFilterMaxWidth] = useState("");
  const [filterMinDepth, setFilterMinDepth] = useState("");
  const [filterMaxDepth, setFilterMaxDepth] = useState("");
  // const [selectedColor, setSelectedColor] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedShapes, setSelectedShapes] = useState([]);

  const handleFilterMinPriceOnChange = (ev) => {
    setFilterMinPrice(ev.target.value);
  };
  const handleFilterMaxPriceOnChange = (ev) => {
    setFilterMaxPrice(ev.target.value);
  };
  const handleFilterMinLengthOnChange = (ev) => {
    setFilterMinLength(ev.target.value);
  };
  const handleFilterMaxLengthOnChange = (ev) => {
    setFilterMaxLength(ev.target.value);
  };
  const handleFilterMinWidthOnChange = (ev) => {
    setFilterMinWidth(ev.target.value);
  };
  const handleFilterMaxWidthOnChange = (ev) => {
    setFilterMaxWidth(ev.target.value);
  };
  const handleFilterMinDepthOnChange = (ev) => {
    setFilterMinDepth(ev.target.value);
  };
  const handleFilterMaxDepthOnChange = (ev) => {
    setFilterMaxDepth(ev.target.value);
  };

  const handleTypeFilterOnChange = (typeName) => {
    const thisSelectedType = typeName;
    if (selectedTypes.length) {
      setSelectedTypes((prev) => {
        if (prev.filter((type) => type === thisSelectedType).length) {
          return [...prev.filter((type) => type !== thisSelectedType)];
        } else {
          return [...prev, thisSelectedType];
        }
      });
    } else {
      setSelectedTypes(() => {
        return [thisSelectedType];
      });
    }
  };

  const handleShapeFilterOnChange = (shapeName) => {
    const thisSelectedShapes = shapeName;
    if (selectedShapes.length) {
      setSelectedShapes((prev) => {
        if (prev.filter((shape) => shape === thisSelectedShapes).length) {
          return [...prev.filter((shape) => shape !== thisSelectedShapes)];
        } else {
          return [...prev, thisSelectedShapes];
        }
      });
    } else {
      setSelectedShapes(() => {
        return [thisSelectedShapes];
      });
    }
  };
  const type = selectedTypes.toString();
  const shape = selectedShapes.toString();

  const handleFormSubmit = (e) => {
    const min_price = filterMinPrice ? filterMinPrice : "";
    const max_price = filterMaxPrice ? filterMaxPrice : "";
    const min_length = filterMinLength ? filterMinLength : "";
    const max_length = filterMaxLength ? filterMaxLength : "";
    const min_width = filterMinWidth ? filterMinWidth : "";
    const max_width = filterMaxWidth ? filterMaxWidth : "";
    const min_depth = filterMinDepth ? filterMinDepth : "";
    const max_depth = filterMaxDepth ? filterMaxDepth : "";
    const name = props.filter ? props.filter : "";
    const business_id = props.businessId ? props.businessId : "";
    const type_id = type ? type : "";
    const shape_id = shape ? shape : "";
    let options = {
      min_price: min_price,
      max_price: max_price,
      min_length: min_length,
      max_length: max_length,
      min_width: min_width,
      max_width: max_width,
      min_depth: min_depth,
      max_depth: max_depth,
      name: name,
      business_id: business_id,
      type_id: type_id,
      shape_id: shape_id,
    };
    Http.callApi(url.customer_item_filter, options)
      .then((response) => {
        props.itemData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    handleFormSubmit();
  }, []);

  //Text Input Label Style When input has data
  useEffect(() => {
    filterMinPrice.length > 0
      ? (document.getElementsByClassName(
          "filter_input_currency_sign"
        )[0].style.display = "flex")
      : (document.getElementsByClassName(
          "filter_input_currency_sign"
        )[0].style.display = "none");

    filterMaxPrice.length > 0
      ? (document.getElementsByClassName(
          "filter_input_currency_sign"
        )[1].style.display = "flex")
      : (document.getElementsByClassName(
          "filter_input_currency_sign"
        )[1].style.display = "none");
  }, [filterMinPrice, filterMaxPrice]);

  return (
    <form
      className="mb-5 mb-lg-0 search_filters_wrapper"
      onSubmit={(e) => handleFormSubmit(e.preventDefault())}
    >
      <div className="filter_section">
        <h5 className="app_card_title">Price</h5>
        <div className="filter_input_wrapper">
          <span className="filter_input_currency_sign">$</span>
          <input
            type="number"
            min={1}
            id="filterMinPrice"
            className="filter_input"
            onChange={(ev) => handleFilterMinPriceOnChange(ev)}
            placeholder=" "
            value={filterMinPrice}
          />
          <label htmlFor="filterMinPrice" className="filter_input_placeholder">
            Min. Price
          </label>
        </div>
        <div className="filter_input_wrapper">
          <span className="filter_input_currency_sign">$</span>
          <input
            type="number"
            min={1}
            id="filterMaxPrice"
            className="filter_input"
            onChange={(ev) => handleFilterMaxPriceOnChange(ev)}
            placeholder=" "
            value={filterMaxPrice}
          />
          <label htmlFor="filterMaxPrice" className="filter_input_placeholder">
            Max. Price
          </label>
        </div>
      </div>

      <hr className="filter_section_divider" />
      <div className="filter_section">
        <h5 className="app_card_title">Type</h5>
        <TypesFilter
          selectedTypes={selectedTypes}
          onCheckedFunc={handleTypeFilterOnChange}
        />
      </div>
      <hr className="filter_section_divider" />
      <div className="filter_section">
        <h5 className="app_card_title">Shape</h5>
        <ShapeFilter
          selectedShapes={selectedShapes}
          onCheckedFunc={handleShapeFilterOnChange}
        />
      </div>
      <hr className="filter_section_divider" />
      <div className="filter_section">
        <h5 className="app_card_title">Dimensions</h5>
        <div className="filter_sub_section">
          <h5 className="filter_sub_section_title">Length</h5>
          <div className="d-flex justify-content-between">
            <div className="filter_input_wrapper me-3">
              <input
                type="number"
                min={0}
                id="filterMinLength"
                onChange={(ev) => handleFilterMinLengthOnChange(ev)}
                value={filterMinLength}
                className="filter_input"
                placeholder=" "
              />
              <label
                htmlFor="filterMinLength"
                className="filter_input_placeholder"
              >
                Min.(mm)
              </label>
            </div>
            <div className="filter_input_wrapper">
              <input
                type="number"
                min={0}
                id="filterMaxLength"
                onChange={(ev) => handleFilterMaxLengthOnChange(ev)}
                value={filterMaxLength}
                className="filter_input"
                placeholder=" "
              />
              <label
                htmlFor="filterMaxLength"
                className="filter_input_placeholder"
              >
                Max.(mm)
              </label>
            </div>
          </div>
        </div>
        <div className="filter_sub_section">
          <h5 className="filter_sub_section_title">Width</h5>
          <div className="d-flex justify-content-between">
            <div className="filter_input_wrapper me-3">
              <input
                type="number"
                min={0}
                id="filterMinWidth"
                onChange={(ev) => handleFilterMinWidthOnChange(ev)}
                value={filterMinWidth}
                className="filter_input"
                placeholder=" "
              />
              <label
                htmlFor="filterMinWidth"
                className="filter_input_placeholder"
              >
                Min.(mm)
              </label>
            </div>
            <div className="filter_input_wrapper">
              <input
                type="number"
                min={0}
                id="filterMaxWidth"
                onChange={(ev) => handleFilterMaxWidthOnChange(ev)}
                value={filterMaxWidth}
                className="filter_input"
                placeholder=" "
              />
              <label
                htmlFor="filterMaxWidth"
                className="filter_input_placeholder"
              >
                Max.(mm)
              </label>
            </div>
          </div>
        </div>
        <div className="filter_sub_section">
          <h5 className="filter_sub_section_title">Depth</h5>
          <div className="d-flex justify-content-between">
            <div className="filter_input_wrapper me-3">
              <input
                type="number"
                min={0}
                className="filter_input"
                onChange={(ev) => handleFilterMinDepthOnChange(ev)}
                value={filterMinDepth}
                id="filterMinDepth"
                placeholder=" "
              />
              <label
                htmlFor="filterMinDepth"
                className="filter_input_placeholder"
              >
                Min.(mm)
              </label>
            </div>
            <div className="filter_input_wrapper">
              <input
                type="number"
                min={0}
                id="filterMaxDepth"
                onChange={(ev) => handleFilterMaxDepthOnChange(ev)}
                value={filterMaxDepth}
                className="filter_input"
                placeholder=" "
              />
              <label
                htmlFor="filterMaxDepth"
                className="filter_input_placeholder"
              >
                Max.(mm)
              </label>
            </div>
          </div>
        </div>
      </div>
      <button className="primaryLink_btn w-100" type="submit">
        Apply Filters
      </button>
      <button className="filter_reset_btn">Reset</button>
    </form>
  );
};

export default FilterModule;
