import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import BottomNavigationBar from "./BottomNavigationBar";

const CustomerLayout = () => {
  const location = useLocation();

  // Determine if the current path is a giveaway page other than the base "/giveaway/"
  const isGiveawaySubpage =
    location.pathname.startsWith("/giveaway/") &&
    location.pathname !== "/giveaway/";

  return (
    <>
      {!isGiveawaySubpage && location.pathname !== "/login" && <Header />}

      <Outlet />

      {!isGiveawaySubpage && location.pathname !== "/login" ? (
        <>
          <BottomNavigationBar />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerLayout;
