import React from "react";
import { MdFlightTakeoff } from "react-icons/md";
import uuid from "react-uuid";

const ShippingInsuranceCard = ({
  options,
  onSelect,
  selectedIndex,
  loading,
  cost,
  insurance,
}) => {
  return (
    <>
      <div className="px-4 flex flex-row" key={uuid()}>
        <MdFlightTakeoff />
        <p className="ml-3 font-semibold md:font-medium text-base">
          Shipping & Insurance
        </p>
      </div>

      <p className="px-4 text-sm md:text-sm mb-2">
        Please select the Shipping Method you'd prefer
      </p>

      <div className="mt-6">
        <div className="flex flex-row mb-1">
          <p className="px-4 font-medium">Option</p>
        </div>
        <hr />
        {options.map((ship, index) => {
          return (
            <>
              <div
                key={index}
                onClick={() => {
                  onSelect({
                    ship: ship,
                    index: index,
                  });
                }}
                className="px-4 row py-4 justify-content-start align-items-start"
                style={{
                  backgroundColor:
                    selectedIndex === index ? "#3B37DA" : "transparent",
                }}
              >
                <p
                  className="font-semibold md:font-medium text-xs md:text-base"
                  style={{
                    color: selectedIndex === index ? "#ffffff" : "#000000",
                  }}
                >
                  {ship.option_name}
                </p>
              </div>
              <hr />
            </>
          );
        })}

        {selectedIndex != null ? (
          <div className="flex flex-row mt-4 mb-4">
            <div style={{ flex: 1 }} />
            <div>
              <p className="mb-2">Your Shipping cost is:</p>
              <h4 className="text-2xl text-center font-semibold">
                {loading ? "Calculating" : cost ? `\$${cost?.toFixed(2)}` : ""}
              </h4>
            </div>

            <div style={{ flex: 1 }} />

            <div className="mr-4">
              <p className="mb-2">You're insured up to:</p>
              <h4 className="text-2xl text-center font-semibold">
                {loading
                  ? "Calculating"
                  : insurance
                  ? `$${insurance?.toFixed(2)}`
                  : `\$${(0.0).toFixed(2)}`}
              </h4>
            </div>

            <div style={{ flex: 1 }} />
          </div>
        ) : null}

        <p className="px-4 text-xs md:text-sm font-semibold md:font-medium mt-4">
          Insurance is the buyers responsibility and is to pay the cost if they
          want insurance on any purchased goods. If the parcel goes missing in
          transit and/or is damaged, the seller holds no responsibility and
          there will be no refunds.
        </p>
      </div>
    </>
  );
};

export default ShippingInsuranceCard;
