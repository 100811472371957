import React, { useEffect } from "react";

import aboutImg1 from "../../../assets/images/img_about_us.png";
import aboutImg2 from "../../../assets/images/img_about_us_1.jpg";
import aboutImg3 from "../../../assets/images/img_about_us_2.jpg";
import Footer from "../../Layouts/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center py-8">
          <div className="">
            <img
              src={aboutImg1}
              alt="About Opal Empire"
              className="w-full h-[60vh] md:h-[75vh] object-cover rounded-2xl"
            />
          </div>
          <div className="px-4">
            <h4 className="font-semibold mt-4 text-base md:text-lg tracking-normal">
              About Opal Empire
            </h4>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              It all started with a show... Outback Opal Hunters.
              <br />
              On a winter evening in June 2019, opal fever hit Opal Empire
              Founder and Director Jullian.
              <br />
              <br />
              After just one episode, Jullian knew this was something he could
              do. He turned to his partner and said, "We can do this, I'll do
              the buying and selling, and you can cut the opal and make the
              jewellery. "She said, "let's go mining!" That was the beginning of
              something magical, and they haven't looked back.
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              Opal Empire was established in 2020, just as the world was
              transitioning into a global pandemic - Opal Empire became a beckon
              of light in the growing darkness. While the whole country was
              trapped in a lockdown, many looked for savour online in search of
              opal's beauty, but it became increasingly scarcer. A massive hole
              in the industry was exposed, and through Jullian's research, he
              found an opportunity to improve the industry and customers'
              overall experience.
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              Throughout Jullian's search, he found that something was missing
              in the level of quality and presentation. One thing really stood
              out... there wasn't a lot of quality rough opal online. Customers'
              online experience of searching and buying opal was often
              overwhelming and daunting, with many falling prey to misleading
              ads or pictures. Jullian knew what he had to do... he had to go
              straight to the source!
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="px-4">
            <h4 className="font-semibold mt-4 text-base md:text-lg">
              Timing, luck, and the 'Opal Gods' were on his side.
            </h4>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              During the lockdown, Jullian was constantly travelling to Coober
              Pedy for work. During his trips, he started purchasing rough opal.
              Opal Empire is more than a logistics business or just selling
              opal. It's committed to sourcing the highest quality, natural
              material directly from Australian outback mines to sell directly
              to the public worldwide while providing an enjoyable and memorable
              online experience. Opal Empire's customer-first mindset sets the
              company a cut above the rest, along with its pure quality of opal.
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              Opal Empire sells opal to all corners of the globe and caters to
              all opal enthusiasts ranging from hobbyists to professional opal
              cutters. We understand the world's changing nature and are
              committed to change alongside it, constantly adapting how to
              present its opal and the entire experience purchasing process to
              its loyal customer.
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              Our mission is to provide easy and interactive access to
              high-quality opal while consistently levelling up your online
              experience. We want to bridge the gap between vendors and
              customers, showcasing exclusive behind-the-scenes footage
              throughout our platforms. Convenience, ease, and excellence are
              always front of mind. Opal Empire strives to achieve complete
              transparency. All images provided are a true representation of
              each opal - what you see is what you get.
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img
              src={aboutImg2}
              alt="About Opal Empire"
              className="w-full h-[70vh] object-cover rounded-2xl"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center py-8">
          <div>
            <img
              src={aboutImg3}
              alt="About Opal Empire"
              className="w-full h-[70vh] object-cover rounded-2xl"
            />
          </div>
          <div className="px-4">
            <h4
              className="font-semibold mt-4 text-base md:text-lg"
              style={{ fontWeight: "600" }}
            >
              Our Core Values
            </h4>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              - Customer Focus
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              - High Quality
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              - Adaptability
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              - Remarkable Beauty
            </p>
            <p className="font-regular mt-4 text-sm md:text-lg tracking-normal">
              - Transparency
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
