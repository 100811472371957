import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const usePersistentUUID = () => {
  const [userUUID, setUserUUID] = useState(() => {
    // Immediately invoked function to set the initial state based on localStorage
    return localStorage.getItem("userUUID") || "";
  });

  useEffect(() => {
    let userId = userUUID || localStorage.getItem("userUUID");

    // Check if UUID exists, if not generate a new one
    if (!userId) {
      // No UUID, generate a new one and store it
      userId = uuidv4();
      localStorage.setItem("userUUID", userId);
    }

    // Update state with the existing or new userId
    setUserUUID(userId);
  }, [userUUID]);

  return userUUID;
};

export default usePersistentUUID;
