import React from "react";

const UserProfileCard = ({ profile }) => {
  return (
    <>
      <div className="border border-grey px-4 py-4 rounded-xl mb-3">
        <div className="flex flex-row items-center">
          <img
            src={
              profile?.image ??
              "https://opalempire.s3.ap-southeast-2.amazonaws.com/blank.png"
            }
            className="h-16 md:h-20 w-16 md:w-20 rounded-full"
            alt={`${profile?.first_name ?? ""} ${profile?.last_name ?? ""}`}
          />
          <div className="ml-4">
            <p className="text-base md:text-lg font-semibold">
              {profile?.first_name ?? "Not Logged In"}{" "}
              {profile?.last_name ?? ""}
            </p>
            <p className="text-sm md:text-base font-normal">
              {profile?.email ?? ""}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileCard;
