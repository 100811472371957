import React, { useState } from "react";
import stripeIcon from "../../../assets/images/stripe.png";
import afterpayIcon from "../../../assets/images/ic_afterpay.png";

const CheckoutPaymentMethodPresentState = ({
  selectedPayment,
  onSelectPayment,
}) => {
  const onPaymentMethodSelect = (option) => {
    console.log(option);
    onSelectPayment(option);
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          className="flex flex-row px-4 py-2 mb-2 items-center justify-center"
          onClick={() => onPaymentMethodSelect({ paymentMethodId: "stripe" })}
        >
          <img src={stripeIcon} className="w-10 h-10" alt="Stripe" />
          <p className="font-medium text-base ml-4">Credit/Debit Card</p>
          <div style={{ flex: 1 }} />
          <input
            className="h-5 w-5"
            checked={selectedPayment?.paymentMethodId === "stripe"}
            onClick={() => onPaymentMethodSelect({ paymentMethodId: "stripe" })}
            type="radio"
          />
        </div>
        {selectedPayment?.paymentMethodId === "stripe" && (
          <>
            <p className="px-4 mt-2 mb-4 text-sm font-medium">
              You will be prompted to enter your Payment Details when you click
              Pay & Place Order
            </p>
          </>
        )}
      </div>

      <div
        className="flex flex-row px-4 py-2 items-center justify-center"
        onClick={() => onPaymentMethodSelect({ paymentMethodId: "afterpay" })}
      >
        <img
          src={afterpayIcon}
          className="w-10 h-10"
          alt="Afterpay - Powered by Stripe"
        />
        <p className="font-medium text-base ml-4">Afterpay</p>
        <div style={{ flex: 1 }} />
        <input
          className="h-5 w-5"
          checked={selectedPayment?.paymentMethodId === "afterpay"}
          onClick={() => onPaymentMethodSelect({ paymentMethodId: "afterpay" })}
          type="radio"
        />
      </div>
      {selectedPayment?.paymentMethodId === "afterpay" && (
        <>
          <p className="px-4 mt-2 mb-4 text-sm font-medium">
            Just keep in mind, Afterpay adds some additional Processing Fees to
            your order. They're shown in the Order Summary
          </p>
        </>
      )}
    </>
  );
};

export default CheckoutPaymentMethodPresentState;
