import React from "react";

const Memberships = () => {
  return (
    <>
      <div className="container mx-auto py-4 mt-3">
        <p className="text-xl">Oi bungee</p>
      </div>
    </>
  );
};

export default Memberships;
