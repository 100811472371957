import React from "react";
import Lottie from "react-lottie-player";

import animation from "../../assets/animations/loader.json";

const SmallerLoading = () => {
  return (
    <>
      <Lottie
        loop
        play
        animationData={animation}
        style={{ width: 112, height: 48 }}
      />
    </>
  );
};

export default SmallerLoading;
