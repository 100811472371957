import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  // autoClose: 500,
});

function customStylesDataTable() {
  const customStyles = {
    headCells: {
      style: {
        "&:last-child": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        "&:first-child": {
          justifyContent: "center",
        },
        "&:last-child": {
          justifyContent: "center",
        },
      },
    },
  };
  return customStyles;
}

function insertAfter(referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function isErrorMessage(name, message, empty) {
  var el = document.createElement("span");
  el.classList.add("validation_text_invalid");
  if (empty) {
    el.innerHTML = message;
  } else {
    el.innerHTML = humanize(name) + " " + message;
  }
  var div = document.getElementById(name);
  // if (div.nextSibling != null) {
  //     div.nextSibling.remove()
  // }
  div.classList.add("is-invalid");
  insertAfter(div, el);
}

function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

function isError(error) {
  var errors_entries = Object.entries(error);
  if (errors_entries) {
    var allDivsCollections = document.getElementsByClassName(
      "validation_text_invalid"
    );
    var arr = Array.from(allDivsCollections);
    arr.forEach((singleElement) => {
      singleElement.classList.remove("is-invalid");
      singleElement.remove();
    });
    errors_entries.forEach((errorAll) => {
      let type = errorAll[1].type;
      let name = errorAll[1].ref.name;
      let message = "";
      let empty = "";
      if (type === "required") {
        if (errorAll[1].message) {
          message = errorAll[1].message;
          empty = errorAll[1].message;
        } else {
          message = "is required.";
        }
        isErrorMessage(name, message, empty);
      } else if (type === "minLength") {
        message = errorAll[1].message;
        isErrorMessage(name, message);
      } else if (type === "maxLength") {
        message = errorAll[1].message;
        isErrorMessage(name, message);
      } else if (type === "validate") {
        if (errorAll[1].message) {
          message = errorAll[1].message;
          empty = errorAll[1].message;
        } else {
          message = "Confirm Password Field is requeid";
        }
        isErrorMessage(name, message, empty);
      } else if (type === "min") {
        message = "min number is 0";
        isErrorMessage(name, message);
      } else if (type === "max") {
        message = "max number is 100";
        isErrorMessage(name, message);
      } else if (type === "validate") {
        message = errorAll[1].message;
        isErrorMessage(name, message);
      } else if (type === "pattern") {
        message = errorAll[1].message;
        isErrorMessage(name, message);
      } else {
        message = "required.";
        isErrorMessage(name, message);
      }
    });
  }
}

function configHeaderAxios() {
  if (localStorage.getItem("authType") === "customer") {
    const config = {
      headers: {
        "content-type": "application/json",
        authorization:
          `Bearer ` + localStorage.getItem("customer_access_token"),
        env: "test",
      },
    };
    return config;
  } else if (localStorage.getItem("authType") === "vendor") {
    const config = {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ` + localStorage.getItem("vendor_access_token"),
        env: "test",
      },
    };
    return config;
  } else {
    const config = {
      headers: {
        "content-type": "application/json",
        env: "test",
      },
    };
    return config;
  }
}

function errorResponse(error) {
  toast.error(error);
  if (error.response.status === 422) {
    let errorData = error.response.data;
    if (errorData) {
      var errors = Object.values(errorData);
      if (errors) {
        errors.forEach((err) => {
          toast.error(humanize(err));
        });
      }
    }
  } else if (error.response.status === 401) {
    let errorData = error.response.data;
    localStorage.clear();
    toast.error(errorData);
  } else if (error.response.data.message) {
    toast.error(error.response.data.message);
  }
}

function successResponse(response) {
  toast.success(response);
  if (response.status === 200) {
    if (response.data.message) {
      toast.success(response.data.message);
    }
  }
}

export {
  errorResponse,
  successResponse,
  isError,
  configHeaderAxios,
  customStylesDataTable,
};
