import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ThumbnailSlider = ({ product }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
      >
        {product.image.map((item, index) => {
          return item.type === "photo" ? (
            <>
              <div key={index}>
                <img
                  src={item.item}
                  onClick={() => {}}
                  className="object-cover h-[50vh] md:h-[60vh] w-full rounded-xl"
                  alt="product"
                />
              </div>
            </>
          ) : (
            <div key={`thumb-${index}`}>
              <video
                className="object-cover h-[50vh] md:h-[60vh] w-full rounded-xl"
                onClick={() => {}}
                autoPlay={false}
                controls
                muted
                loop
              >
                <source src={item.item} />
              </video>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default ThumbnailSlider;
