import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";

const ProfileOptionCard = ({
  name,
  icon,
  route,
  authRequired,
  authenticated,
}) => {
  const navigate = useNavigate();

  const navigateToRoute = (route) => {
    if (authRequired && !authenticated) {
      alert("You must login to access this feature");
      return;
    }
    navigate(route);
  };

  return (
    <>
      <div
        onClick={() => navigateToRoute(route)}
        className="cursor-pointer flex flex-row justify-center items-center px-4 py-4 mb-3 border border-grey transition-all hover:border-opalBlue rounded-xl"
      >
        <div className="w-12 md:w-12 h-12 md:h-12 rounded-full bg-opalLightBlue flex justify-center items-center">
          {icon}
        </div>
        <p className="ml-4 text-sm md:text-base font-semibold md:font-medium">
          {name}
        </p>
        <div style={{ flex: 1 }} />
        <ChevronRight />
      </div>
    </>
  );
};

export default ProfileOptionCard;
