import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import { toast } from "react-toastify";

const OrderProcessing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    placeOrder();
  }, []);

  const placeOrder = async () => {
    const cartId = localStorage.getItem("cart_id");
    const customerId = localStorage.getItem("customer_id");
    const paymentId = localStorage.getItem("payment_intent_id");

    // Required Data
    const addressId = localStorage.getItem("address_id");
    const paymentTypeId = localStorage.getItem("payment_type");
    const deliveryCode = localStorage.getItem("delivery_code");

    // Pricing Data
    const deliveryFee = localStorage.getItem("delivery_fee");
    const total = localStorage.getItem("total");
    const subtotal = localStorage.getItem("subtotal");
    const gst = localStorage.getItem("gst");
    const processingFee = localStorage.getItem("processing_fee");

    Http.callApi(url.complete_web_app_order, {
      cartId: cartId,
      customerId: customerId,
      paymentId: paymentId,
      addressId: addressId,
      payment_type: paymentTypeId,
      delivery_code: deliveryCode,
      delivery_fee: deliveryFee,
      total: total,
      subtotal: subtotal,
      gst: gst,
      processing_fee: processingFee,
    }).then((response) => {
      console.log("RESPONSE DATA:", response.data);
      if (response.data.success === true) {
        navigate(`/order?id=` + response.data?.order_id ?? "");
      } else {
        toast.error(response.data.message);
      }
    });
  };

  return <></>;
};

export default OrderProcessing;
