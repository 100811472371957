import React from "react";

import DeliveryAddressInitialState from "./DeliveryAddressInitialState";
import CommonLoader from "../AppLoaders/CommonLoader";

import { AiOutlineHome } from "react-icons/ai";
import { IoBriefcaseOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";
import { FaRegTrashAlt } from "react-icons/fa";

const DeliveryAddress = ({
  profile,
  isLoading,
  addresses,
  onAddTap,
  onDelete,
}) => {
  return profile?.addresses ? (
    <>
      <div className="border border-grey px-4 md:px-6 py-4 md:py-6 mb-3 rounded-xl">
        <h1 className="font-semibold text-lg md:text-lg mb-3">
          Delivery Addresses
        </h1>

        {!isLoading ? (
          addresses?.length > 0 ? (
            <>
              {addresses?.map((address) => {
                return (
                  <>
                    <div
                      key={address?._id ?? ""}
                      className="flex flex-row mt-4 md:mt-6 justify-center items-center"
                    >
                      <div className="bg-opalLightBlue px-3 md:px-3 py-3 md:py-3 rounded-full">
                        {address.address_type === 0 ? (
                          <>
                            <AiOutlineHome className="text-2xl md:text-2xl text-opalBlue" />
                          </>
                        ) : address.address_type === 1 ? (
                          <IoBriefcaseOutline className="text-2xl md:text-2xl text-opalBlue" />
                        ) : (
                          <SlLocationPin className="text-2xl md:text-2xl text-opalBlue" />
                        )}
                      </div>
                      <div className="flex flex-col ml-3">
                        <p className="font-medium text-sm md:text-base">
                          {address.address_type === 0
                            ? "Home"
                            : address.address_type === 1
                            ? "Work"
                            : "Other"}
                        </p>
                        <p className="font-regular text-xs md:text-base w-5/6 md:w-full">
                          {address.address}
                        </p>
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        onClick={() => {
                          console.log("Delete Delivery Address");
                          onDelete(address?._id ?? "");
                        }}
                      >
                        <FaRegTrashAlt
                          className="text-md md:text-base"
                          color={"#ff0000"}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
              <DeliveryAddressInitialState
                addressLength={addresses?.length}
                onAddAddress={onAddTap}
              />
            </>
          ) : (
            <>
              <DeliveryAddressInitialState
                addressLength={addresses?.length}
                onAddAddress={onAddTap}
              />
            </>
          )
        ) : (
          <>
            <div className="flex flex-col justify-center items-center">
              <CommonLoader />
              <p className="font-medium text-sm md:text-base">
                Updating Delivery Addresses
              </p>
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="border border-grey px-4 md:px-6 py-4 md:py-6 mb-3 items-center justify-center flex rounded-xl">
        <h1 className="font-semibold text-sm text-center md:text-lg">
          Your Delivery Addresses will show once you're logged in.
        </h1>
      </div>
    </>
  );
};

export default DeliveryAddress;
